import { formatPhoneNumber } from "../../shared/util/formatPhoneNumber";
import { formatCpfOrCnpj } from "../../shared/util/formatCpfOrCnpj";
import {
  AuthContextProps,
  FinancingData,
  FormHookDispState,
  RegistrationData,
} from "../../shared/data/types";

interface FetchProps {
  sendRequest: (
    url: string,
    method?: string,
    body?: BodyInit,
    headers?: HeadersInit,
    successMessage?: boolean,
    zeroResultsKey?: string
  ) => Promise<any>;
  auth: AuthContextProps;
}

// GET CUSTOMER FINANCINGS /////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
interface GetCustomerFinancingsProps extends FetchProps {
  customerId: string;
  setLoadedFinancings: React.Dispatch<React.SetStateAction<FinancingData[]>>;
  setShowLoadMore: React.Dispatch<React.SetStateAction<boolean>>;
  multiplier: number;
  hidration: boolean;
  setFirstLoad?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const getCustomerFinancings = async (
  props: GetCustomerFinancingsProps
) => {
  const {
    customerId,
    sendRequest,
    auth,
    setLoadedFinancings,
    setShowLoadMore,
    multiplier,
    hidration,
    setFirstLoad,
  } = props;

  try {
    const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/financings/customer/${customerId}/${multiplier}`;
    const responseData: {
      financings: FinancingData[];
      hasMoreItems: boolean;
    } = await sendRequest(apiUrl, "GET", null, {
      Authorization: "Bearer " + auth.token,
    });

    setShowLoadMore(responseData.hasMoreItems);
    setLoadedFinancings((prevValues) => {
      if (!!prevValues && prevValues.length > 0 && !hidration) {
        return [...prevValues, ...responseData.financings];
      } else {
        return responseData.financings || [];
      }
    });
    if (setFirstLoad) {
      setFirstLoad(true);
    }
  } catch (err) {}
};

// GET PROPOSAL FINANCINGS /////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
interface GetProposalFinancingsProps extends FetchProps {
  proposalId: string;
  setLoadedFinancings: React.Dispatch<React.SetStateAction<FinancingData[]>>;
  setShowLoadMore: React.Dispatch<React.SetStateAction<boolean>>;
  multiplier: number;
  hidration: boolean;
  setFirstLoad?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const getProposalFinancings = async (
  props: GetProposalFinancingsProps
) => {
  const {
    proposalId,
    sendRequest,
    auth,
    setLoadedFinancings,
    setShowLoadMore,
    multiplier,
    hidration,
    setFirstLoad,
  } = props;

  try {
    const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/financings/proposal/${proposalId}/${multiplier}`;
    const responseData: {
      financings: FinancingData[];
      hasMoreItems: boolean;
    } = await sendRequest(apiUrl, "GET", null, {
      Authorization: "Bearer " + auth.token,
    });

    setShowLoadMore(responseData.hasMoreItems);
    setLoadedFinancings((prevValues) => {
      if (!!prevValues && prevValues.length > 0 && !hidration) {
        return [...prevValues, ...responseData.financings];
      } else {
        return responseData.financings || [];
      }
    });
    if (setFirstLoad) {
      setFirstLoad(true);
    }
  } catch (err) {}
};

// CHECK IF INTEGRATOR CAN REQ BTG FIN /////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
interface CheckIfIntegratorCanReqBTGFinProps extends FetchProps {
  setCanFinanceInBTG: React.Dispatch<React.SetStateAction<boolean>>;
}

export const checkIfIntegratorCanReqBTGFin = async (
  props: CheckIfIntegratorCanReqBTGFinProps
) => {
  const { sendRequest, auth, setCanFinanceInBTG } = props;

  try {
    const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/financings/btg/integrator-check`;
    const responseData: {
      check: boolean;
    } = await sendRequest(apiUrl, "GET", null, {
      Authorization: "Bearer " + auth.token,
    });

    setCanFinanceInBTG(responseData.check || false);
  } catch (err) {}
};

// GENERATE CREDIT ANALYSIS ////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
interface GenerateBTGCreditAnalysisProps extends FetchProps {
  formState: FormHookDispState;
  cid: string;
  pid: string;
  setLoadedFinancings: React.Dispatch<React.SetStateAction<FinancingData[]>>;
  stage: "SIMULATION" | "DEAL";
}

export const generateBTGCreditAnalysis = async (
  props: GenerateBTGCreditAnalysisProps
) => {
  const { sendRequest, auth, formState, cid, pid, setLoadedFinancings, stage } =
    props;

  try {
    const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/financings/btg/credit-analysis/${cid}/${pid}`;
    const responseData: { financing: FinancingData } = await sendRequest(
      apiUrl,
      "POST",
      JSON.stringify({
        customerIdentification:
          formState?.inputs?.customerIdentification?.value,
        customerName: formState?.inputs?.customerName?.value,
        customerEmail: formState?.inputs?.customerEmail?.value,
        distributorCnpj: formState?.inputs?.distributorCnpj?.value,
        integratorCnpj: formState?.inputs?.integratorCnpj?.value,
        monthlyBilling: +formState?.inputs?.monthlyBilling?.value,
        electricBillAverage: +formState?.inputs?.electricBillAverage?.value,
        projectValue: +formState?.inputs?.projectValue?.value,
        cep: formState?.inputs?.cep?.value,
        address: formState?.inputs?.address?.value,
        number: formState?.inputs?.number?.value,
        complement: formState?.inputs?.complement?.value,
        district: formState?.inputs?.district?.value,
        city: formState?.inputs?.city?.value,
        state: formState?.inputs?.state?.value,
        stage: stage,
      }),
      {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.token,
      },
      true
    );

    setLoadedFinancings((prevValues) => {
      return [...prevValues, responseData.financing];
    });
  } catch (err) {}
};

// ADD PRE-APPROVED FIN TO PROPOSAL ////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
interface AddPreApprovedFinToProposalProps extends FetchProps {
  fid: string;
  options: string[];
}

export const addPreApprovedFinToProposalProps = async (
  props: AddPreApprovedFinToProposalProps
) => {
  const { fid, sendRequest, auth, options } = props;

  try {
    const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/financings/single/proposal/${fid}`;
    await sendRequest(apiUrl, "POST", JSON.stringify({ props: options }), {
      "Content-Type": "application/json",
      Authorization: "Bearer " + auth.token,
    });
  } catch (err) {}
};

// EDIT FINANCING STAGE ////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
interface EditFinancingStageProps extends FetchProps {
  fid: string;
  setLoadedFinancings: React.Dispatch<React.SetStateAction<FinancingData[]>>;
}

export const editFinancingStage = async (props: EditFinancingStageProps) => {
  const { fid, sendRequest, auth, setLoadedFinancings } = props;

  try {
    const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/financings/single/stage/${fid}`;
    const responseData: { financing: FinancingData } = await sendRequest(
      apiUrl,
      "PUT",
      null,
      {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.token,
      }
    );

    setLoadedFinancings((prevValues) => {
      prevValues = prevValues?.map((item) => {
        if (item?._id === fid) {
          item = responseData.financing;
        }

        return item;
      });

      return prevValues;
    });
  } catch (err) {}
};

// GET USER REGISTRATION ///////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
interface GetUserRegistrationProps extends FetchProps {
  setLoadedRegistration: React.Dispatch<React.SetStateAction<RegistrationData>>;
  setRegistrationCheck: React.Dispatch<React.SetStateAction<boolean>>;
}

export const getUserRegistration = async (props: GetUserRegistrationProps) => {
  const { sendRequest, auth, setLoadedRegistration, setRegistrationCheck } =
    props;

  try {
    const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/registrations/single`;
    const responseData: { registration: RegistrationData } = await sendRequest(
      apiUrl,
      "GET",
      null,
      {
        Authorization: "Bearer " + auth.token,
      }
    );

    setLoadedRegistration(responseData.registration || null);
    setRegistrationCheck(true);
  } catch (err) {}
};

// SUBMIT USER REGISTRATION ////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////
interface SubmitUserRegistrationProps extends FetchProps {
  formState: FormHookDispState;
  setLoadedRegistration: React.Dispatch<React.SetStateAction<RegistrationData>>;
}

export const submitUserRegistration = async (
  props: SubmitUserRegistrationProps
) => {
  const { sendRequest, auth, formState, setLoadedRegistration } = props;

  try {
    const apiUrl = `${process.env.REACT_APP_BACKEND_URL}/registrations/new`;
    const responseData: { registration: RegistrationData } = await sendRequest(
      apiUrl,
      "POST",
      JSON.stringify({
        cnpj: formatCpfOrCnpj(formState?.inputs?.cnpj?.value?.toString()),
        corporateName: formState?.inputs?.corporateName?.value,
        name: formState?.inputs?.name?.value,
        email: formState?.inputs?.email?.value,
        phone: formatPhoneNumber(formState?.inputs?.phone?.value?.toString()),
      }),
      {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.token,
      },
      true
    );

    setLoadedRegistration(responseData.registration);
  } catch (err) {}
};
