import { getPricingCompIdFromName } from "../util/getPricingCompIdFromName";
import { formatCpfOrCnpj } from "../../shared/util/formatCpfOrCnpj";
import {
  AuthContextProps,
  SettingData,
  FormHookDispState,
  SettingsContextProps,
  WebhookAction,
  OmnichannelData,
  OmnichannelDataChatMessage,
  OmnichannelWppAccountMessagesResponse,
} from "../../shared/data/types";

export interface FetchProps {
  sendRequest: (
    url: string,
    method?: string,
    body?: BodyInit,
    headers?: HeadersInit,
    successMessage?: boolean,
    zeroResultsKey?: string
  ) => Promise<any>;
  auth: AuthContextProps;
}

// EDIT ORIGINS SETTINGS ////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
interface EditOriginsSettingsProps extends FetchProps {
  formState: FormHookDispState;
  originsLength: number;
  settingsCtx: SettingsContextProps;
}

export const editOriginsSettings = async (props: EditOriginsSettingsProps) => {
  const { sendRequest, auth, settingsCtx, formState, originsLength } = props;

  let origins: string[] = [];
  for (let i = 0; i < originsLength; i++) {
    origins.push(formState.inputs?.[`origin${i}`]?.value as string);
  }

  try {
    let apiUrl = `${process.env.REACT_APP_BACKEND_URL}/settings/user/origins/${auth.userId}`;
    const responseData: { settings: SettingData } = await sendRequest(
      apiUrl,
      "PUT",
      JSON.stringify({ origins }),
      {
        Authorization: "Bearer " + auth.token,
        "Content-Type": "application/json",
      },
      true
    );

    settingsCtx.updateOriginsHandler(responseData.settings.origins);
  } catch (err) {}
};

// EDIT SALES LOST REASONS SETTINGS ////////////////////////////////////
////////////////////////////////////////////////////////////////////////
interface EditSalesLostReasonsSettingsProps extends FetchProps {
  formState: FormHookDispState;
  reasonsLength: number;
  settingsCtx: SettingsContextProps;
}

export const editSalesLostReasonsSettings = async (
  props: EditSalesLostReasonsSettingsProps
) => {
  const { sendRequest, auth, settingsCtx, formState, reasonsLength } = props;

  let reasons: string[] = [];
  for (let i = 0; i < reasonsLength; i++) {
    reasons.push(formState.inputs?.[`reason${i}`]?.value as string);
  }

  try {
    let apiUrl = `${process.env.REACT_APP_BACKEND_URL}/settings/user/sales-lost-reasons/${auth.userId}`;
    const responseData: { settings: SettingData } = await sendRequest(
      apiUrl,
      "PUT",
      JSON.stringify({ reasons }),
      {
        Authorization: "Bearer " + auth.token,
        "Content-Type": "application/json",
      },
      true
    );

    settingsCtx.updateSalesLostReasonsHandler(
      responseData.settings.salesLostReasons
    );
  } catch (err) {}
};

// EDIT REJECT REASONS SETTINGS ////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
interface EditRejectReasonsSettingsProps extends FetchProps {
  formState: FormHookDispState;
  reasonsLength: number;
  settingsCtx: SettingsContextProps;
}

export const editRejectReasonsSettings = async (
  props: EditRejectReasonsSettingsProps
) => {
  const { sendRequest, auth, settingsCtx, formState, reasonsLength } = props;

  let reasons: string[] = [];
  for (let i = 0; i < reasonsLength; i++) {
    reasons.push(formState.inputs?.[`reason${i}`]?.value as string);
  }

  try {
    let apiUrl = `${process.env.REACT_APP_BACKEND_URL}/settings/user/reject-reasons/${auth.userId}`;
    const responseData: { settings: SettingData } = await sendRequest(
      apiUrl,
      "PUT",
      JSON.stringify({ reasons }),
      {
        Authorization: "Bearer " + auth.token,
        "Content-Type": "application/json",
      },
      true
    );

    settingsCtx.updateRejectReasonsHandler(responseData.settings.rejectReasons);
  } catch (err) {}
};

// EDIT NOTES TYPES ////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
interface EditNotesTypesProps extends FetchProps {
  formState: FormHookDispState;
  typesLength: number;
  settingsCtx: SettingsContextProps;
}

export const editNotesTypes = async (props: EditNotesTypesProps) => {
  const { sendRequest, auth, settingsCtx, formState, typesLength } = props;

  let types: string[] = [];
  for (let i = 0; i < typesLength; i++) {
    types.push(formState.inputs?.[`type${i}`]?.value as string);
  }

  try {
    let apiUrl = `${process.env.REACT_APP_BACKEND_URL}/settings/user/notes-types/${auth.userId}`;
    const responseData: { settings: SettingData } = await sendRequest(
      apiUrl,
      "PUT",
      JSON.stringify({ types }),
      {
        Authorization: "Bearer " + auth.token,
        "Content-Type": "application/json",
      },
      true
    );

    settingsCtx.updateNotesTypesHandler(responseData.settings.noteTypes);
  } catch (err) {}
};

// EDIT COMPANY POSITIONS //////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
interface EditCompanyPositionsProps extends FetchProps {
  formState: FormHookDispState;
  positionsLength: number;
  settingsCtx: SettingsContextProps;
}

export const editCompanyPositions = async (
  props: EditCompanyPositionsProps
) => {
  const { sendRequest, auth, settingsCtx, formState, positionsLength } = props;

  let positions: string[] = [];
  for (let i = 0; i < positionsLength; i++) {
    positions.push(formState.inputs?.[`position${i}`]?.value as string);
  }

  try {
    let apiUrl = `${process.env.REACT_APP_BACKEND_URL}/settings/user/company-positions/${auth.userId}`;
    const responseData: { settings: SettingData } = await sendRequest(
      apiUrl,
      "PUT",
      JSON.stringify({ positions }),
      {
        Authorization: "Bearer " + auth.token,
        "Content-Type": "application/json",
      },
      true
    );

    settingsCtx.updateCompanyPositionsHandler(
      responseData.settings.companyPositions || []
    );
  } catch (err) {}
};

// EDIT STANDARD FOLDERS ///////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
interface EditStandardFoldersProps extends FetchProps {
  formState: FormHookDispState;
  foldersLength: number;
  settingsCtx: SettingsContextProps;
}

export const editStandardFolders = async (props: EditStandardFoldersProps) => {
  const { sendRequest, auth, settingsCtx, formState, foldersLength } = props;

  let stdFolders: string[] = [];
  for (let i = 0; i < foldersLength; i++) {
    stdFolders.push(formState.inputs?.[`folder${i}`]?.value as string);
  }

  try {
    let apiUrl = `${process.env.REACT_APP_BACKEND_URL}/settings/user/standard-folders/${auth.userId}`;
    const responseData: { settings: SettingData } = await sendRequest(
      apiUrl,
      "PUT",
      JSON.stringify({ stdFolders }),
      {
        Authorization: "Bearer " + auth.token,
        "Content-Type": "application/json",
      },
      true
    );

    settingsCtx.updateStdFoldersHandler(responseData.settings.stdFolders || []);
  } catch (err) {}
};

// EDIT DISABLED SUPPLIERS /////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
interface EditDisabledSuppliersProps extends FetchProps {
  formState: FormHookDispState;
  settingsCtx: SettingsContextProps;
  suppliers: string[];
}

export const editDisabledSuppliers = async (
  props: EditDisabledSuppliersProps
) => {
  const { sendRequest, auth, settingsCtx, formState, suppliers } = props;

  let disabledSuppliers: string[] = [];
  suppliers.forEach((supplier) => {
    if (!formState?.inputs?.[supplier]?.value) {
      disabledSuppliers.push(supplier);
    }
  });

  try {
    let apiUrl = `${process.env.REACT_APP_BACKEND_URL}/settings/user/suppliers/${auth.userId}`;
    const responseData: { settings: SettingData } = await sendRequest(
      apiUrl,
      "PUT",
      JSON.stringify({
        disabledSuppliers,
        fortlevEmail: formState?.inputs?.fortlevEmail?.value,
        fortlevPassword: formState?.inputs?.fortlevPassword?.value,
      }),
      {
        Authorization: "Bearer " + auth.token,
        "Content-Type": "application/json",
      },
      true
    );

    settingsCtx.updateDisabledSuppliersHandler(
      responseData.settings.disabledSuppliers || []
    );

    settingsCtx.updateSuppliersHandler(responseData.settings?.suppliers);
  } catch (err) {}
};

// EDIT INTERFACE //////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
interface EditInterfaceProps extends FetchProps {
  formState: FormHookDispState;
  settingsCtx: SettingsContextProps;
  menus: string[];
}

export const editInterface = async (props: EditInterfaceProps) => {
  const { sendRequest, auth, settingsCtx, formState, menus } = props;

  let hm: string[] = [];
  menus.forEach((hiddenMenu) => {
    if (!formState?.inputs?.[hiddenMenu]?.value) {
      hm.push(hiddenMenu);
    }
  });

  try {
    let apiUrl = `${process.env.REACT_APP_BACKEND_URL}/settings/user/interface/${auth.userId}`;
    const responseData: { settings: SettingData } = await sendRequest(
      apiUrl,
      "PUT",
      JSON.stringify({
        hiddenMenus: hm,
      }),
      {
        Authorization: "Bearer " + auth.token,
        "Content-Type": "application/json",
      },
      true
    );

    settingsCtx.updateInterfaceHandler(responseData.settings.interface);
  } catch (err) {}
};

// EDIT AUTO PRICING SETTINGS //////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
interface EditAutoPricingSettingsProps extends FetchProps {
  formState: FormHookDispState;
  rulesLength: number;
  settingsCtx: SettingsContextProps;
}

export const editAutoPricingSettings = async (
  props: EditAutoPricingSettingsProps
) => {
  const { sendRequest, auth, settingsCtx, formState, rulesLength } = props;

  let settings: { power: number; margin: number }[] = [];
  for (let i = 0; i < rulesLength; i++) {
    settings.push({
      power: +formState.inputs?.[`power${i}`]?.value?.toString(),
      margin: +formState.inputs?.[`margin${i}`]?.value?.toString(),
    });
  }

  settings[settings.length - 1].power = 9999999999;

  try {
    let apiUrl = `${process.env.REACT_APP_BACKEND_URL}/settings/user/auto-pricing/${auth.userId}`;
    const responseData: { settings: SettingData } = await sendRequest(
      apiUrl,
      "PUT",
      JSON.stringify({ settings }),
      {
        Authorization: "Bearer " + auth.token,
        "Content-Type": "application/json",
      },
      true
    );

    settingsCtx.updateAutoPricingHandler(responseData.settings.autoPricing);
  } catch (err) {}
};

// EDIT PREFERENCES SETTINGS ///////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
interface EditPreferencesSettingsProps extends FetchProps {
  formState: FormHookDispState;
  settingsCtx: SettingsContextProps;
}

export const editPreferencesSettings = async (
  props: EditPreferencesSettingsProps
) => {
  const { sendRequest, auth, settingsCtx, formState } = props;
  const stdOwnersInput =
    (formState?.inputs?.stdOwners?.value as string[]) || [];
  const stdOwnersEmails =
    (stdOwnersInput
      ?.map((data) => {
        const [first, ...rest] = data.split("-");
        const remainder = rest?.join("-")?.trim() || "";

        return remainder;
      })
      ?.filter((data) => {
        return !!data;
      }) as string[]) || [];
  let stdOwnersIds =
    auth.vendors
      ?.filter((data) => {
        return stdOwnersEmails?.includes?.(data?.email);
      })
      ?.map((data) => {
        return data?._id;
      }) || [];

  try {
    let apiUrl = `${process.env.REACT_APP_BACKEND_URL}/settings/user/preferences/${auth.userId}`;
    const responseData: { settings: SettingData } = await sendRequest(
      apiUrl,
      "PUT",
      JSON.stringify({
        losses: +formState.inputs.losses.value,
        inflation: +formState.inputs.inflation.value,
        validity: +formState.inputs.validity.value,
        availabilityTaxDiscount:
          formState?.inputs?.availabilityTaxDiscount?.value || false,
        useManagerKits: formState.inputs.useManagerKits.value,
        powerDistCompany: formState.inputs.powerDistCompany.value || "",
        hidePropNumber: formState.inputs.hidePropNumber?.value || false,
        simultFactorResidential:
          +formState.inputs.simultFactorResidential.value || 30,
        simultFactorCommercial:
          +formState.inputs.simultFactorCommercial.value || 70,
        simultFactorIndustrial:
          +formState.inputs.simultFactorIndustrial.value || 50,
        useFastPDFRendering: false,
        distributeLeads: formState?.inputs?.distributeLeads?.value || false,
        usePreSalesKanban: formState?.inputs?.usePreSalesKanban?.value || false,
        autoCreateProjAndInst:
          formState?.inputs?.autoCreateProjAndInst?.value || false,
        stdKitDiscount: +formState.inputs.stdKitDiscount?.value || 0,
        maxKitDiscount: +formState.inputs.maxKitDiscount?.value || 0,
        stdOwners: stdOwnersIds,
        reqEmailField: formState.inputs.reqEmailField?.value || false,
        reqDocField: formState.inputs.reqDocField?.value || false,
        importWindow: +formState.inputs.importWindow?.value || 24,
        stdSupplierName: formState.inputs.stdSupplierName.value || "",
        stdSupplierCNPJ:
          formatCpfOrCnpj(
            formState.inputs.stdSupplierCNPJ.value?.toString() || ""
          ) || "",
      }),
      {
        Authorization: "Bearer " + auth.token,
        "Content-Type": "application/json",
      },
      true
    );

    settingsCtx.updatePreferencesHandler(responseData.settings.preferences);
  } catch (err) {}
};

// EDIT PERMISSIONS SETTINGS ///////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
interface EditPermissionsSettingsProps extends FetchProps {
  formState: FormHookDispState;
  settingsCtx: SettingsContextProps;
}

export const editPermissionsSettings = async (
  props: EditPermissionsSettingsProps
) => {
  const { sendRequest, auth, settingsCtx, formState } = props;

  try {
    let apiUrl = `${process.env.REACT_APP_BACKEND_URL}/settings/user/permissions/${auth.userId}`;
    const responseData: { settings: SettingData } = await sendRequest(
      apiUrl,
      "PUT",
      JSON.stringify({
        vendorPriceEdit: formState.inputs.vendorPriceEdit.value,
      }),
      {
        Authorization: "Bearer " + auth.token,
        "Content-Type": "application/json",
      },
      true
    );

    settingsCtx.updatePermissionsHandler(responseData.settings.permissions);
  } catch (err) {}
};

// EDIT PRICING TYPE ///////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
interface EditPricingTypeSettingsProps extends FetchProps {
  type: "SIMPLE" | "DETAILED";
  calc: "MARKUP" | "GROSS_MARGIN";
  settingsCtx: SettingsContextProps;
}

export const editPricingType = async (props: EditPricingTypeSettingsProps) => {
  const { sendRequest, auth, settingsCtx, type, calc } = props;

  try {
    let apiUrl = `${process.env.REACT_APP_BACKEND_URL}/settings/user/auto-pricing-type/${auth.userId}`;
    const responseData: { settings: SettingData } = await sendRequest(
      apiUrl,
      "PUT",
      JSON.stringify({
        type: type,
        calc: calc,
      }),
      {
        Authorization: "Bearer " + auth.token,
        "Content-Type": "application/json",
      },
      true
    );

    settingsCtx.updateAutoPricingTypeHandler(
      responseData.settings.autoPricingType
    );
    settingsCtx.updateAutoPricingCalcHandler(
      responseData.settings.autoPricingCalc
    );
    settingsCtx.updateAutoPricingDetailedHandler(
      responseData.settings.autoPricingDetailed
    );
  } catch (err) {}
};

// ADD PRICING COMPONENT ///////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
interface AddPricingComponentProps extends FetchProps {
  formState: FormHookDispState;
  settingsCtx: SettingsContextProps;
}

export const addPricingComponent = async (props: AddPricingComponentProps) => {
  const { sendRequest, auth, settingsCtx, formState } = props;

  try {
    let apiUrl = `${process.env.REACT_APP_BACKEND_URL}/settings/user/pricing-component/${auth.userId}`;
    const responseData: { settings: SettingData } = await sendRequest(
      apiUrl,
      "POST",
      JSON.stringify({
        name: formState.inputs?.name?.value || "",
        isRevenue:
          formState.inputs?.type?.value !== "Comissão" &&
          formState.inputs?.type?.value !== "Comissão Individual" &&
          formState.inputs?.type?.value !== "Margem de Negociação" &&
          formState.inputs?.revenurOrCost?.value === "Receita",
        type:
          getPricingCompIdFromName(
            formState.inputs?.type?.value as
              | "Valor Fixo"
              | "Percentual sobre Valor Final"
              | "Percentual sobre Serviço"
              | "Percentual sobre Kit"
              | "Percentual sobre Lucro"
              | "Valor por kWp"
              | "Valor por Módulo"
              | "Comissão"
              | "Comissão Individual"
              | "Margem de Negociação"
          ) || "",
        range: formState.inputs?.range?.value || "KWP",
        restriction:
          (formState.inputs?.restriction?.value !== "STANDARD"
            ? formState.inputs?.restriction?.value
            : null) || null,
        vendor:
          formState.inputs?.type?.value === "Comissão Individual"
            ? formState.inputs?.vendor?.value
            : null,
        service: formState.inputs?.service?.value || "",
        condType:
          formState.inputs?.condType?.value !== "NONE"
            ? formState.inputs?.condType?.value || undefined
            : undefined,
        condLogic: formState.inputs?.condLogic?.value || undefined,
        condNumValue:
          +formState.inputs?.condNumValue?.value
            ?.toString()
            ?.replace(/\D/g, "")
            ?.trim() || undefined,
        condStrValue: formState.inputs?.condStrValue?.value || undefined,
        discountFromComission:
          formState.inputs?.discountFromComission?.value || undefined,
        proportion: +formState.inputs?.proportion?.value || undefined,
        comissionPermission:
          formState.inputs?.comissionPermission?.value || undefined,
      }),
      {
        Authorization: "Bearer " + auth.token,
        "Content-Type": "application/json",
      },
      true
    );

    settingsCtx.updateAutoPricingDetailedHandler(
      responseData.settings?.autoPricingDetailed
    );
  } catch (err) {}
};

// EDIT PRICING COMPONENT NAME /////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
interface EditPricingComponentNameProps extends FetchProps {
  formState: FormHookDispState;
  settingsCtx: SettingsContextProps;
  componentId: string;
}

export const editPricingComponentName = async (
  props: EditPricingComponentNameProps
) => {
  const { sendRequest, auth, settingsCtx, formState, componentId } = props;

  try {
    let apiUrl = `${process.env.REACT_APP_BACKEND_URL}/settings/user/pricing-component/${auth.userId}/${componentId}`;
    const responseData: { settings: SettingData } = await sendRequest(
      apiUrl,
      "PATCH",
      JSON.stringify({
        name: formState.inputs?.name?.value || "",
      }),
      {
        Authorization: "Bearer " + auth.token,
        "Content-Type": "application/json",
      },
      true
    );

    settingsCtx.updateAutoPricingDetailedHandler(
      responseData.settings?.autoPricingDetailed
    );
  } catch (err) {}
};

// EDIT COMPONENT RULES ////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
interface EditComponentRulesProps extends FetchProps {
  formState: FormHookDispState;
  rulesLength: number;
  settingsCtx: SettingsContextProps;
  componentId: string;
}

export const editComponentRules = async (props: EditComponentRulesProps) => {
  const {
    sendRequest,
    auth,
    settingsCtx,
    formState,
    rulesLength,
    componentId,
  } = props;

  let rules: { power: number; value: number }[] = [];
  for (let i = 0; i < rulesLength; i++) {
    rules.push({
      power: +formState.inputs?.[`power${i}`]?.value?.toString(),
      value: +formState.inputs?.[`value${i}`]?.value?.toString(),
    });
  }

  rules[rules.length - 1].power = 9999999999;

  try {
    let apiUrl = `${process.env.REACT_APP_BACKEND_URL}/settings/user/pricing-component/rules/${auth.userId}/${componentId}`;
    const responseData: { settings: SettingData } = await sendRequest(
      apiUrl,
      "PUT",
      JSON.stringify({ rules }),
      {
        Authorization: "Bearer " + auth.token,
        "Content-Type": "application/json",
      },
      true
    );

    settingsCtx.updateAutoPricingDetailedHandler(
      responseData.settings?.autoPricingDetailed
    );
  } catch (err) {}
};

// CLONE PRICING COMPONENT /////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
interface ClonePricingComponentProps extends FetchProps {
  settingsCtx: SettingsContextProps;
  componentId: string;
  restriction: string | null;
  service: string | null;
}

export const clonePricingComponent = async (
  props: ClonePricingComponentProps
) => {
  const { sendRequest, auth, settingsCtx, componentId, restriction, service } =
    props;

  try {
    let apiUrl = `${process.env.REACT_APP_BACKEND_URL}/settings/user/pricing-component-clone/${componentId}`;
    const responseData: { settings: SettingData } = await sendRequest(
      apiUrl,
      "POST",
      JSON.stringify({ restriction, service }),
      {
        "Content-Type": "application/json",
        Authorization: "Bearer " + auth.token,
      }
    );

    settingsCtx.updateAutoPricingDetailedHandler(
      responseData.settings?.autoPricingDetailed
    );
  } catch (err) {}
};

// DELETE PRICING COMPONENT ////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
interface DeletePricingComponentProps extends FetchProps {
  settingsCtx: SettingsContextProps;
  componentId: string;
}

export const deletePricingComponent = async (
  props: DeletePricingComponentProps
) => {
  const { sendRequest, auth, settingsCtx, componentId } = props;

  try {
    let apiUrl = `${process.env.REACT_APP_BACKEND_URL}/settings/user/pricing-component/${auth.userId}/${componentId}`;
    const responseData: { settings: SettingData } = await sendRequest(
      apiUrl,
      "DELETE",
      null,
      {
        Authorization: "Bearer " + auth.token,
      },
      true
    );

    settingsCtx.updateAutoPricingDetailedHandler(
      responseData.settings?.autoPricingDetailed
    );
  } catch (err) {}
};

// ADD BANK ////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
interface AddBankProps extends FetchProps {
  formState: FormHookDispState;
  settingsCtx: SettingsContextProps;
}

export const addBank = async (props: AddBankProps) => {
  const { sendRequest, auth, settingsCtx, formState } = props;

  const formData = new FormData();
  formData.append("image", (formState.inputs.image?.value as File) || null);
  formData.append("name", (formState.inputs.name?.value as string) || "");

  try {
    let apiUrl = `${process.env.REACT_APP_BACKEND_URL}/settings/user/fin-bank/${auth.userId}`;
    const responseData: { settings: SettingData } = await sendRequest(
      apiUrl,
      "POST",
      formData,
      {
        Authorization: "Bearer " + auth.token,
      },
      true
    );

    settingsCtx.updateAutoFinancingHandler(
      responseData.settings?.autoFinancing
    );
  } catch (err) {}
};

// EDIT BANK ///////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
interface EditBankProps extends FetchProps {
  formState: FormHookDispState;
  settingsCtx: SettingsContextProps;
  bankId: string;
}

export const editBank = async (props: EditBankProps) => {
  const { sendRequest, auth, settingsCtx, formState, bankId } = props;

  const formData = new FormData();
  formData.append("image", formState.inputs.image?.value as File);
  formData.append("name", formState.inputs.name?.value as string);

  try {
    let apiUrl = `${process.env.REACT_APP_BACKEND_URL}/settings/user/fin-bank/${auth.userId}/${bankId}`;
    const responseData: { settings: SettingData } = await sendRequest(
      apiUrl,
      "PATCH",
      formData,
      {
        Authorization: "Bearer " + auth.token,
      },
      true
    );

    settingsCtx.updateAutoFinancingHandler(
      responseData.settings?.autoFinancing
    );
  } catch (err) {}
};

// EDIT BANK FIN OPTS //////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
interface EditBankFinOptsProps extends FetchProps {
  formState: FormHookDispState;
  optsLength: number;
  settingsCtx: SettingsContextProps;
  bankId: string;
}

export const editBankFinOpts = async (props: EditBankFinOptsProps) => {
  const { sendRequest, auth, settingsCtx, formState, optsLength, bankId } =
    props;

  let opts: {
    name: string;
    type: "PRICE" | "SAC";
    installments: number;
    entryPerc: number;
    interestRate: number;
    includeAsStd: boolean;
    gracePeriod: number;
    insurance: boolean;
  }[] = [];
  for (let i = 0; i < optsLength; i++) {
    opts.push({
      name: formState.inputs?.[`name${i}`]?.value?.toString(),
      type: "PRICE",
      interestRate: +formState.inputs?.[`interestRate${i}`]?.value?.toString(),
      installments: +formState.inputs?.[`installments${i}`]?.value?.toString(),
      entryPerc: +formState.inputs?.[`entryPerc${i}`]?.value?.toString(),
      gracePeriod: +formState.inputs?.[`gracePeriod${i}`]?.value?.toString(),
      insurance: formState.inputs?.[`insurance${i}`]?.value === "Sim",
      includeAsStd: formState.inputs?.[`includeAsStd${i}`]?.value as boolean,
    });
  }

  try {
    let apiUrl = `${process.env.REACT_APP_BACKEND_URL}/settings/user/fin-bank/options/${auth.userId}/${bankId}`;
    const responseData: { settings: SettingData } = await sendRequest(
      apiUrl,
      "PUT",
      JSON.stringify({ opts }),
      {
        Authorization: "Bearer " + auth.token,
        "Content-Type": "application/json",
      },
      true
    );

    settingsCtx.updateAutoFinancingHandler(
      responseData.settings?.autoFinancing
    );
  } catch (err) {}
};

// DELETE BANK /////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
interface DeleteBankProps extends FetchProps {
  settingsCtx: SettingsContextProps;
  bankId: string;
}

export const deleteBank = async (props: DeleteBankProps) => {
  const { sendRequest, auth, settingsCtx, bankId } = props;

  try {
    let apiUrl = `${process.env.REACT_APP_BACKEND_URL}/settings/user/fin-bank/${auth.userId}/${bankId}`;
    const responseData: { settings: SettingData } = await sendRequest(
      apiUrl,
      "DELETE",
      null,
      {
        Authorization: "Bearer " + auth.token,
      },
      true
    );

    settingsCtx.updateAutoFinancingHandler(
      responseData.settings?.autoFinancing
    );
  } catch (err) {}
};

// EDIT INDIVIDUAL PERMISSION //////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
interface EditIndividualPermissionProps extends FetchProps {
  formState: FormHookDispState;
  settingsCtx: SettingsContextProps;
  vendorId: string;
}

export const editIndividualPermission = async (
  props: EditIndividualPermissionProps
) => {
  const { sendRequest, auth, settingsCtx, formState, vendorId } = props;

  try {
    let apiUrl = `${process.env.REACT_APP_BACKEND_URL}/settings/user/vendor/permission/${auth.userId}/${vendorId}`;
    const responseData: { settings: SettingData } = await sendRequest(
      apiUrl,
      "PUT",
      JSON.stringify({
        customersList: formState.inputs?.customersList?.value,
        proposalsList: formState.inputs?.proposalsList?.value,
        salesKanban: formState.inputs?.salesKanban?.value,
        projectKanban: formState.inputs?.projectKanban?.value,
        installationKanban: formState.inputs?.installationKanban?.value,
        afterSalesKanban: formState.inputs?.afterSalesKanban?.value,
        preSalesKanban: formState.inputs?.preSalesKanban?.value,
        administrativeKanban: formState.inputs?.administrativeKanban?.value,
        pricingTab:
          formState.inputs?.pricingTab?.value ||
          formState.inputs?.pricingEdit?.value ||
          false,
        pricingEdit: formState.inputs?.pricingEdit?.value || false,
        salesManager:
          (formState.inputs?.salesManager?.value &&
            formState.inputs?.customersList?.value &&
            formState.inputs?.proposalsList?.value &&
            formState.inputs?.salesKanban?.value) ||
          false,
        installationManager:
          (formState.inputs?.installationManager?.value &&
            formState.inputs?.installationKanban?.value) ||
          false,
        projectManager:
          (formState.inputs?.projectManager?.value &&
            formState.inputs?.projectKanban?.value) ||
          false,
        afterSalesManager:
          (formState.inputs?.afterSalesManager?.value &&
            formState.inputs?.afterSalesKanban?.value) ||
          false,
        preSalesManager:
          (formState.inputs?.preSalesManager?.value &&
            formState.inputs?.preSalesKanban?.value) ||
          false,
        administrativeManager:
          (formState.inputs?.administrativeManager?.value &&
            formState.inputs?.administrativeKanban?.value) ||
          false,
        configManager: formState.inputs?.configManager?.value || false,
        contractManager:
          (formState.inputs?.contractManager?.value &&
            formState.inputs?.salesKanban?.value) ||
          false,
        kitsManager:
          (formState.inputs?.kitsManager?.value &&
            formState?.inputs?.pricingEdit?.value) ||
          false,
        employeesManager: formState.inputs?.employeesManager?.value || false,
        viewAllChatsManager:
          formState.inputs?.viewAllChatsManager?.value || false,
      }),
      {
        Authorization: "Bearer " + auth.token,
        "Content-Type": "application/json",
      },
      true
    );

    settingsCtx.updateIndividualPermissionsHandler(
      responseData.settings.individualPermissions
    );
  } catch (err) {}
};

// EDIT PROPOSAL SLUG //////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
interface EditProposalSlugProps extends FetchProps {
  formState: FormHookDispState;
  settingsCtx: SettingsContextProps;
}

export const editProposalSlug = async (props: EditProposalSlugProps) => {
  const { sendRequest, auth, settingsCtx, formState } = props;

  try {
    let apiUrl = `${process.env.REACT_APP_BACKEND_URL}/settings/user/proposal-slug/${auth.userId}`;
    const responseData: { settings: SettingData } = await sendRequest(
      apiUrl,
      "PUT",
      JSON.stringify({
        slug: formState.inputs?.slug?.value || "",
      }),
      {
        Authorization: "Bearer " + auth.token,
        "Content-Type": "application/json",
      },
      true
    );

    settingsCtx.updateProposalSlugHandler(responseData.settings.proposalSlug);
  } catch (err) {}
};

// ADD TEAM ////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
interface AddTeamProps extends FetchProps {
  formState: FormHookDispState;
  settingsCtx: SettingsContextProps;
  members: string[];
}

export const addTeam = async (props: AddTeamProps) => {
  const { sendRequest, auth, settingsCtx, formState, members } = props;

  try {
    let apiUrl = `${process.env.REACT_APP_BACKEND_URL}/settings/user/team`;
    const responseData: { settings: SettingData } = await sendRequest(
      apiUrl,
      "POST",
      JSON.stringify({
        name: formState?.inputs?.name?.value,
        leader: formState?.inputs?.leader?.value,
        type: formState?.inputs?.type?.value,
        members: members,
      }),
      {
        Authorization: "Bearer " + auth.token,
        "Content-Type": "application/json",
      },
      true
    );

    settingsCtx.updateTeamsHandler(responseData.settings?.teams || []);
  } catch (err) {}
};

// EDIT TEAM ////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
interface EditTeamProps extends FetchProps {
  teamId: string;
  formState: FormHookDispState;
  settingsCtx: SettingsContextProps;
  members: string[];
}

export const editTeam = async (props: EditTeamProps) => {
  const { sendRequest, auth, settingsCtx, formState, members, teamId } = props;

  try {
    let apiUrl = `${process.env.REACT_APP_BACKEND_URL}/settings/user/team/${teamId}`;
    const responseData: { settings: SettingData } = await sendRequest(
      apiUrl,
      "PUT",
      JSON.stringify({
        name: formState?.inputs?.name?.value,
        leader: formState?.inputs?.leader?.value,
        type: formState?.inputs?.type?.value,
        members: members,
      }),
      {
        Authorization: "Bearer " + auth.token,
        "Content-Type": "application/json",
      },
      true
    );

    settingsCtx.updateTeamsHandler(responseData.settings?.teams || []);
  } catch (err) {}
};

// DELETE TEAM /////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
interface DeleteTeamProps extends FetchProps {
  teamId: string;
  settingsCtx: SettingsContextProps;
}

export const deleteTeam = async (props: DeleteTeamProps) => {
  const { sendRequest, auth, settingsCtx, teamId } = props;

  try {
    let apiUrl = `${process.env.REACT_APP_BACKEND_URL}/settings/user/team/${teamId}`;
    const responseData: { settings: SettingData } = await sendRequest(
      apiUrl,
      "DELETE",
      null,
      {
        Authorization: "Bearer " + auth.token,
      },
      true
    );

    settingsCtx.updateTeamsHandler(responseData.settings?.teams || []);
  } catch (err) {}
};

// ADD RESTRICTION /////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
interface AddRestrictionProps extends FetchProps {
  formState: FormHookDispState;
  settingsCtx: SettingsContextProps;
  locLength: number;
  vendLength: number;
  service?: string | null;
}

export const addRestriction = async (props: AddRestrictionProps) => {
  const {
    sendRequest,
    auth,
    settingsCtx,
    formState,
    locLength,
    vendLength,
    service,
  } = props;

  let locations: string[] = [];
  for (let i = 0; i < locLength; i++) {
    locations.push(
      formState?.inputs?.[`city${i}`]?.value?.toString()?.trim() as string
    );
  }

  let vendors: string[] = [];
  for (let i = 0; i < vendLength; i++) {
    vendors.push(
      formState?.inputs?.[`vendor${i}`]?.value?.toString()?.trim() as string
    );
  }

  try {
    let apiUrl = `${process.env.REACT_APP_BACKEND_URL}/settings/user/restriction/${auth.userId}`;
    const responseData: { settings: SettingData } = await sendRequest(
      apiUrl,
      "POST",
      JSON.stringify({
        name: formState?.inputs?.name?.value,
        locations,
        vendors,
        service,
      }),
      {
        Authorization: "Bearer " + auth.token,
        "Content-Type": "application/json",
      },
      true
    );

    settingsCtx.updateAutoPricingDetailedHandler(
      responseData.settings?.autoPricingDetailed
    );
  } catch (err) {}
};

// EDIT RESTRICTION ////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
interface EditRestrictionProps extends FetchProps {
  restrictionId: string;
  formState: FormHookDispState;
  settingsCtx: SettingsContextProps;
  locLength: number;
  vendLength: number;
}

export const editRestriction = async (props: EditRestrictionProps) => {
  const {
    sendRequest,
    auth,
    restrictionId,
    settingsCtx,
    formState,
    locLength,
    vendLength,
  } = props;

  let locations: string[] = [];
  for (let i = 0; i < locLength; i++) {
    locations.push(
      formState?.inputs?.[`city${i}`]?.value?.toString()?.trim() as string
    );
  }

  let vendors: string[] = [];
  for (let i = 0; i < vendLength; i++) {
    vendors.push(
      formState?.inputs?.[`vendor${i}`]?.value?.toString()?.trim() as string
    );
  }

  try {
    let apiUrl = `${process.env.REACT_APP_BACKEND_URL}/settings/user/restriction/${auth.userId}/${restrictionId}`;
    const responseData: { settings: SettingData } = await sendRequest(
      apiUrl,
      "PUT",
      JSON.stringify({
        name: formState?.inputs?.name?.value,
        locations,
        vendors,
      }),
      {
        Authorization: "Bearer " + auth.token,
        "Content-Type": "application/json",
      },
      true
    );

    settingsCtx.updateAutoPricingDetailedHandler(
      responseData.settings?.autoPricingDetailed
    );
  } catch (err) {}
};

// DELETE RESTRICTION ////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
interface DeleteRestrictionProps extends FetchProps {
  restrictionId: string;
  settingsCtx: SettingsContextProps;
}

export const deleteRestriction = async (props: DeleteRestrictionProps) => {
  const { sendRequest, auth, restrictionId, settingsCtx } = props;

  try {
    let apiUrl = `${process.env.REACT_APP_BACKEND_URL}/settings/user/restriction/${auth.userId}/${restrictionId}`;
    const responseData: { settings: SettingData } = await sendRequest(
      apiUrl,
      "DELETE",
      null,
      {
        Authorization: "Bearer " + auth.token,
      },
      true
    );

    settingsCtx.updateAutoPricingDetailedHandler(
      responseData.settings?.autoPricingDetailed
    );
  } catch (err) {}
};

// ADD WEBHOOK ////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
interface AddWebhookProps extends FetchProps {
  formState: FormHookDispState;
  actions: WebhookAction[];
  settingsCtx: SettingsContextProps;
}

export const addWebhook = async (props: AddWebhookProps) => {
  const { sendRequest, auth, settingsCtx, formState, actions } = props;

  try {
    let apiUrl = `${process.env.REACT_APP_BACKEND_URL}/settings/user/webhook`;
    const responseData: { settings: SettingData } = await sendRequest(
      apiUrl,
      "POST",
      JSON.stringify({
        name: formState?.inputs?.name?.value,
        uri: formState?.inputs?.uri?.value,
        actions: actions,
        secret: formState?.inputs?.secret?.value,
      }),
      {
        Authorization: "Bearer " + auth.token,
        "Content-Type": "application/json",
      },
      true
    );

    settingsCtx.updateWebhooksHandler(responseData.settings?.webhooks || []);
  } catch (err) {}
};

// EDIT WEBHOOK ////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
interface EditWebhookProps extends FetchProps {
  webhookId: string;
  formState: FormHookDispState;
  actions: WebhookAction[];
  settingsCtx: SettingsContextProps;
}

export const editWebhook = async (props: EditWebhookProps) => {
  const { sendRequest, auth, settingsCtx, formState, actions, webhookId } =
    props;

  try {
    let apiUrl = `${process.env.REACT_APP_BACKEND_URL}/settings/user/webhook/${webhookId}`;
    const responseData: { settings: SettingData } = await sendRequest(
      apiUrl,
      "PUT",
      JSON.stringify({
        name: formState?.inputs?.name?.value,
        uri: formState?.inputs?.uri?.value,
        actions: actions,
      }),
      {
        Authorization: "Bearer " + auth.token,
        "Content-Type": "application/json",
      },
      true
    );

    settingsCtx.updateWebhooksHandler(responseData.settings?.webhooks || []);
  } catch (err) {}
};

// DELETE WEBHOOK /////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
interface DeleteWebhookProps extends FetchProps {
  webhookId: string;
  settingsCtx: SettingsContextProps;
}

export const deleteWebhook = async (props: DeleteWebhookProps) => {
  const { sendRequest, auth, settingsCtx, webhookId } = props;

  try {
    let apiUrl = `${process.env.REACT_APP_BACKEND_URL}/settings/user/webhook/${webhookId}`;
    const responseData: { settings: SettingData } = await sendRequest(
      apiUrl,
      "DELETE",
      null,
      {
        Authorization: "Bearer " + auth.token,
      },
      true
    );

    settingsCtx.updateWebhooksHandler(responseData.settings?.webhooks || []);
  } catch (err) {}
};

// ADD WPP ACCOUNT ////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
interface AddWppAccountProps extends FetchProps {
  formState: FormHookDispState;
  settingsCtx: SettingsContextProps;
}

export const addWppAccount = (props: AddWppAccountProps) => {
  return new Promise<string>(async (resolve, reject) => {
    const { sendRequest, auth, settingsCtx, formState } = props;

    try {
      let apiUrl = `${process.env.REACT_APP_BACKEND_URL}/omnichannels/user/wpp-account`;
      const responseData: { omnichannels: OmnichannelData } = await sendRequest(
        apiUrl,
        "POST",
        JSON.stringify({
          vendor: formState?.inputs?.vendor?.value,
          phone: formState?.inputs?.phone?.value,
        }),
        {
          Authorization: "Bearer " + auth.token,
          "Content-Type": "application/json",
        },
        true
      );

      const wppAccount = responseData.omnichannels.wppAccounts.find(
        (item) => item.phone === formState?.inputs?.phone?.value
      );
      if (wppAccount) resolve(wppAccount._id);

      settingsCtx.updateWppAccountsHandler(
        responseData.omnichannels?.wppAccounts || []
      );
    } catch (err) {
      // reject(err);
    }
  });
};

// ADD WPP ACCOUNT ////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
interface GetWppAccountMessagesProps extends FetchProps {
  wppAccountId: string;
  chatId: string;
  page: number;
  limit?: number;
}

export const getWppAccountMessages = (props: GetWppAccountMessagesProps) => {
  return new Promise<OmnichannelWppAccountMessagesResponse>(
    async (resolve, reject) => {
      const {
        sendRequest,
        auth,
        wppAccountId,
        chatId,
        page,
        limit = 30,
      } = props;

      try {
        let apiUrl = `${process.env.REACT_APP_BACKEND_URL}/omnichannels/user/wpp-account/${wppAccountId}/${chatId}/messages`;
        const responseData: OmnichannelWppAccountMessagesResponse =
          await sendRequest(
            apiUrl,
            "POST",
            JSON.stringify({
              page,
              limit,
            }),
            {
              Authorization: "Bearer " + auth.token,
              "Content-Type": "application/json",
            },
            true
          );

        resolve(responseData);
      } catch (err) {
        reject(err);
      }
    }
  );
};

// EDIT WPP ACCOUNT ////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
interface EditWppAccountProps extends FetchProps {
  wppAccountId: string;
  formState: FormHookDispState;
  settingsCtx: SettingsContextProps;
}

export const editWppAccount = async (props: EditWppAccountProps) => {
  const { sendRequest, auth, settingsCtx, formState, wppAccountId } = props;

  try {
    let apiUrl = `${process.env.REACT_APP_BACKEND_URL}/omnichannels/user/wpp-account/${wppAccountId}`;
    const responseData: { omnichannels: OmnichannelData } = await sendRequest(
      apiUrl,
      "PUT",
      JSON.stringify({
        phone: formState?.inputs?.name?.value,
        session: formState?.inputs?.uri?.value,
        token: formState?.inputs?.secret?.value,
      }),
      {
        Authorization: "Bearer " + auth.token,
        "Content-Type": "application/json",
      },
      true
    );

    settingsCtx.updateWppAccountsHandler(
      responseData.omnichannels?.wppAccounts || []
    );
  } catch (err) {}
};

// DELETE WPP ACCOUNT /////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
interface DeleteWppAccountProps extends FetchProps {
  wppAccountId: string;
  settingsCtx: SettingsContextProps;
}

export const deleteWppAccount = async (props: DeleteWppAccountProps) => {
  const { sendRequest, auth, settingsCtx, wppAccountId } = props;

  try {
    let apiUrl = `${process.env.REACT_APP_BACKEND_URL}/omnichannels/user/wpp-account/${wppAccountId}`;
    const responseData: { omnichannels: OmnichannelData } = await sendRequest(
      apiUrl,
      "DELETE",
      null,
      {
        Authorization: "Bearer " + auth.token,
      },
      true
    );

    settingsCtx.updateWppAccountsHandler(
      responseData.omnichannels?.wppAccounts || []
    );
  } catch (err) {}
};

// ADD SERVICE /////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
interface AddServiceProps extends FetchProps {
  formState: FormHookDispState;
  settingsCtx: SettingsContextProps;
}

export const addService = async (props: AddServiceProps) => {
  const { sendRequest, auth, settingsCtx, formState } = props;

  try {
    let apiUrl = `${process.env.REACT_APP_BACKEND_URL}/settings/user/service`;
    const responseData: { settings: SettingData } = await sendRequest(
      apiUrl,
      "POST",
      JSON.stringify({
        name: formState.inputs.name?.value || "",
        type: formState.inputs.type?.value || "SERVICE",
      }),
      {
        Authorization: "Bearer " + auth.token,
        "Content-Type": "application/json",
      },
      true
    );

    settingsCtx.updateAfterSalesServicesHandler(
      responseData.settings?.afterSalesServices || []
    );
  } catch (err) {}
};

// EDIT SERVICE ////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
interface EditServiceProps extends FetchProps {
  formState: FormHookDispState;
  settingsCtx: SettingsContextProps;
  serviceId: string;
}

export const editService = async (props: EditServiceProps) => {
  const { sendRequest, auth, settingsCtx, formState, serviceId } = props;

  try {
    let apiUrl = `${process.env.REACT_APP_BACKEND_URL}/settings/user/service/${serviceId}`;
    const responseData: { settings: SettingData } = await sendRequest(
      apiUrl,
      "PUT",
      JSON.stringify({
        name: formState.inputs.name?.value || "",
        type: formState.inputs.type?.value || "SERVICE",
      }),
      {
        Authorization: "Bearer " + auth.token,
        "Content-Type": "application/json",
      },
      true
    );

    settingsCtx.updateAfterSalesServicesHandler(
      responseData.settings?.afterSalesServices
    );
  } catch (err) {}
};

// DELETE SERVICE //////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////
interface DeleteServiceProps extends FetchProps {
  settingsCtx: SettingsContextProps;
  serviceId: string;
}

export const deleteService = async (props: DeleteServiceProps) => {
  const { sendRequest, auth, settingsCtx, serviceId } = props;

  try {
    let apiUrl = `${process.env.REACT_APP_BACKEND_URL}/settings/user/service/${serviceId}`;
    const responseData: { settings: SettingData } = await sendRequest(
      apiUrl,
      "DELETE",
      null,
      {
        Authorization: "Bearer " + auth.token,
      },
      true
    );

    settingsCtx.updateAfterSalesServicesHandler(
      responseData.settings?.afterSalesServices
    );
  } catch (err) {}
};
