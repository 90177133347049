import { useState, Fragment, useEffect } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconButton from "@material-ui/core/IconButton";
import AddRoundedIcon from "@material-ui/icons/AddRounded";

import { ModalZeroResults } from "../../shared/components/UIElements/ModalZeroResults";
import { ButtonLoadMore } from "../../shared/components/UIElements/ButtonLoadMore";
import { SaleDashFinacingItem } from "./SaleDashFinacingItem";
import { PopoverAddBTGFinancing } from "./PopoverAddBTGFinancing";
import { PopoverAddBTGRegistrationRequest } from "./PopoverAddBTGRegistrationRequest";
import { FinancingBTGWarning } from "./FinancingBTGWarning";
import { ModalError } from "../../shared/components/UIElements/ModalError";
import { useHttpClient } from "../../shared/hooks/httpHook";
import { getPastDaysFromDate } from "../../shared/util/getDaysFromDate";
import {
  getCustomerFinancings,
  checkIfIntegratorCanReqBTGFin,
  getUserRegistration,
  getProposalFinancings,
} from "../api/financingsAPI";
import {
  AuthContextProps,
  CustomerDataPopEst,
  FinancingData,
  CustomerData,
  SettingsContextProps,
  RegistrationData,
  ProposalData,
} from "../../shared/data/types";

import "./SaleDash.scss";

interface SaleDashFinancingsProps {
  settingsCtx: SettingsContextProps;
  auth: AuthContextProps;
  customerId: string;
  customer: CustomerDataPopEst | CustomerData;
  proposal?: ProposalData;
  type?:
    | "SALES"
    | "PROJECT"
    | "INSTALLATION"
    | "AFTER_SALES"
    | "PRE_SALES"
    | "ADMINISTRATIVE";
}

export const SaleDashFinancings = (props: SaleDashFinancingsProps) => {
  const { settingsCtx, auth, customerId, customer, type, proposal } = props;
  const {
    error,
    sendRequest,
    clearError,
    isLoading,
    zeroResults,
    clearZeroResults,
  } = useHttpClient();
  const [loadedFinancings, setLoadedFinancings] =
    useState<FinancingData[]>(null);
  const [loadedRegistration, setLoadedRegistration] =
    useState<RegistrationData>(null);
  const [registrationCheck, setRegistrationCheck] = useState<boolean>(false);
  const [firstLoad, setFirstLoad] = useState<boolean>(true);
  const [multiplier, setMultiplier] = useState<number>(0);
  const [showLoadMore, setShowLoadMore] = useState<boolean>(false);
  const [canFinanceInBTG, setCanFinanceInBTG] = useState<boolean>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);
  const [anchorEl2, setAnchorEl2] = useState<null | HTMLButtonElement>(null);

  useEffect(() => {
    checkIfIntegratorCanReqBTGFin({ sendRequest, auth, setCanFinanceInBTG });
    getUserRegistration({
      sendRequest,
      auth,
      setLoadedRegistration,
      setRegistrationCheck,
    });
  }, []);

  useEffect(() => {
    setLoadedFinancings(null);
    setMultiplier(0);
    setShowLoadMore(false);

    if (!!customerId && !proposal) {
      getCustomerFinancings({
        customerId,
        sendRequest,
        auth,
        hidration: true,
        setLoadedFinancings,
        setShowLoadMore,
        multiplier: 0,
        setFirstLoad,
      });
    } else if (!!customerId && !!proposal) {
      getProposalFinancings({
        proposalId: proposal?._id,
        sendRequest,
        auth,
        hidration: true,
        setLoadedFinancings,
        setShowLoadMore,
        multiplier: 0,
        setFirstLoad,
      });
    }
  }, [customerId, proposal]);

  const getCustomerFinancingsHandler = async (mult: number) => {
    await getCustomerFinancings({
      customerId,
      sendRequest,
      auth,
      hidration: false,
      setLoadedFinancings,
      setShowLoadMore,
      multiplier: mult,
    });
  };

  const hasReg = !!loadedRegistration;
  const regStatus = loadedRegistration?.status;
  const daysSinceLastSubmition = hasReg
    ? getPastDaysFromDate(loadedRegistration?.lastSubmitionDate)
    : 0;
  const daysLeft = 60 - daysSinceLastSubmition;
  const canReqReg =
    !hasReg || regStatus === "Nova Análise Liberada" || daysLeft === 0;

  return (
    <Fragment>
      <ModalZeroResults
        show={zeroResults}
        closePopupHandler={clearZeroResults}
      />
      <PopoverAddBTGFinancing
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        auth={auth}
        cid={customer?._id || ""}
        customer={customer}
        setLoadedFinancings={setLoadedFinancings}
        proposal={proposal}
        customerProposals={
          !proposal
            ? ((customer?.proposals || []) as ProposalData[])
            : [proposal]
        }
      />
      <PopoverAddBTGRegistrationRequest
        anchorEl={anchorEl2}
        setAnchorEl={setAnchorEl2}
        auth={auth}
        setLoadedRegistration={setLoadedRegistration}
      />
      <ModalError error={error} onClear={clearError} />
      {(!loadedFinancings ||
        typeof canFinanceInBTG !== "boolean" ||
        !registrationCheck) && (
        <div className="sale-dash__col-right-content-spinner">
          <CircularProgress color="primary" />
        </div>
      )}
      {typeof canFinanceInBTG === "boolean" &&
        !canFinanceInBTG &&
        registrationCheck && (
          <FinancingBTGWarning
            canFinanceInBTG={canFinanceInBTG}
            loadedRegistration={loadedRegistration}
            auth={auth}
            setLoadedRegistration={setLoadedRegistration}
          />
        )}
      {loadedFinancings &&
        loadedFinancings?.length === 0 &&
        typeof canFinanceInBTG === "boolean" &&
        registrationCheck && (
          <p className="sale-dash__col-right-content-empty">
            Este clientes não possui financiamentos
          </p>
        )}
      <div className="sale-dash__proposals">
        {typeof canFinanceInBTG === "boolean" &&
          registrationCheck &&
          loadedFinancings?.length > 0 &&
          loadedFinancings?.map((item, i) => {
            return (
              <SaleDashFinacingItem
                auth={auth}
                customerId={customerId}
                setLoadedFinancings={setLoadedFinancings}
                loadedFinancing={item}
                key={`financing-${i}`}
              />
            );
          })}
        {!isLoading && <div className="sale-dash__add-btn-fixed"></div>}
      </div>
      {/* {loadedFinancings && loadedFinancings?.length === 0 && ( */}
      <div className="sale-dash__col-right-add-item-btn">
        {!isLoading &&
          ((typeof canFinanceInBTG === "boolean" && registrationCheck) ||
            canFinanceInBTG) && (
            <IconButton
              title="Solicitar novo financiamento"
              disabled={!canFinanceInBTG && !canReqReg}
              onClick={(e) => {
                if (canFinanceInBTG) {
                  setAnchorEl(e.currentTarget);
                } else if (canReqReg) {
                  setAnchorEl2(e.currentTarget);
                }
              }}
            >
              <AddRoundedIcon />
            </IconButton>
          )}
        {loadedFinancings && isLoading && <CircularProgress color="primary" />}
      </div>
      {/* )} */}
      <div className="sale-dash__col-right-content-load-more">
        <ButtonLoadMore
          setMultiplier={setMultiplier}
          showLoadMore={showLoadMore}
          isLoading={isLoading}
          fetchItems={getCustomerFinancingsHandler}
          noMargin
        />
      </div>
    </Fragment>
  );
};
