import { useEffect, useContext, useState } from "react";
import { useLocation } from "react-router-dom";

import { AuthContext } from "../../context/authContext";

import "./WidgetZendesk.scss";

interface WidgetZendeskProps {
  zendeskJWT: string;
  isAuth: boolean;
  setIsAuth: React.Dispatch<React.SetStateAction<boolean>>;
}

export const WidgetZendesk = (props: WidgetZendeskProps) => {
  const auth = useContext(AuthContext);
  const { zendeskJWT, isAuth, setIsAuth } = props;
  const [scriptLoaded, setScriptLoaded] = useState<boolean>(false);
  const [hasZE, setHasZE] = useState<boolean>(false);

  const location = useLocation();

  useEffect(() => {
    const existingScript = document.getElementById("ze-snippet");
    var timer1: NodeJS.Timeout | null = null;
    var timer2: NodeJS.Timeout | null = null;

    if (!existingScript) {
      const script = document.createElement("script");
      script.src =
        "https://static.zdassets.com/ekr/snippet.js?key=c11a154b-2d9d-443c-8471-05020438b117";
      script.id = "ze-snippet";
      script.type = "application/javascript";
      document.body.appendChild(script);
      script.onload = () => {
        timer1 = setTimeout(() => {
          setScriptLoaded(true);
        }, 500);

        timer2 = setTimeout(() => {
          setHasZE(true);
        }, 5000);
      };
    } else {
      var timer3 = setTimeout(() => {
        setScriptLoaded(true);
      }, 500);

      var timer4 = setTimeout(() => {
        setHasZE(true);
      }, 5000);
    }

    return () => {
      if (timer1) {
        clearTimeout(timer1);
      }

      if (timer2) {
        clearTimeout(timer2);
      }

      if (timer3) {
        clearTimeout(timer3);
      }

      if (timer4) {
        clearTimeout(timer4);
      }
    };
  }, [location?.pathname, zendeskJWT, auth.isLoggedIn, auth.status]);

  useEffect(() => {
    var timer1: NodeJS.Timeout | null = null;

    if (hasZE && zendeskJWT) {
      zE("messenger", "loginUser", function (callback) {
        callback(zendeskJWT);

        timer1 = setTimeout(() => {
          setIsAuth(true);
        }, 500);
      });
    }

    return () => {
      if (timer1) {
        clearTimeout(timer1);
      }
    };
  }, [hasZE, zendeskJWT]);

  useEffect(() => {
    const laucher = document.getElementById("launcher");
    const laucherWindow = document.getElementsByName("Janela de mensagens");
    const laucherWindow2 = document.getElementsByName("Messaging window");
    const messageBtn = document.querySelector('[title="Fechar mensagem"]');
    const messagewindow = document.querySelector(
      '[title="Mensagem da empresa"]'
    );
    const messageBtn2 = document.querySelector('[title="Close message"]');
    const messagewindow2 = document.querySelector(
      '[title="Message from company"]'
    );
    const unreadMessages = document.querySelector(
      '[data-testid="unread-indicator-container"]'
    );

    if (
      // location?.pathname?.includes("/contato") &&
      auth.isLoggedIn &&
      auth.status === "ACTIVE" &&
      // !!zendeskJWT &&
      // isAuth &&
      !location?.pathname?.includes("/proposta/final") &&
      !location?.pathname?.includes("/institucional") &&
      !location?.pathname?.includes("/kits/fornecedores") &&
      !location?.pathname?.includes("/configuracoes") &&
      !location?.pathname?.includes("/personalizar-proposta")
    ) {
      laucher?.classList?.remove("widget-zendesk--no-display");
      laucherWindow?.[0]?.classList?.remove("widget-zendesk--no-display");
      laucherWindow2?.[0]?.classList?.remove("widget-zendesk--no-display");
      messageBtn?.classList?.remove("widget-zendesk--no-display");
      messagewindow?.classList?.remove("widget-zendesk--no-display");
      messageBtn2?.classList?.remove("widget-zendesk--no-display");
      messagewindow2?.classList?.remove("widget-zendesk--no-display");
      unreadMessages?.classList?.remove("widget-zendesk--no-display");
    } else if (
      location?.pathname?.includes("/login") &&
      !auth.isLoggedIn &&
      !isAuth &&
      !location?.pathname?.includes("/proposta/final") &&
      !location?.pathname?.includes("/institucional")
    ) {
      laucher?.classList?.remove("widget-zendesk--no-display");
      laucherWindow?.[0]?.classList?.remove("widget-zendesk--no-display");
      laucherWindow2?.[0]?.classList?.remove("widget-zendesk--no-display");
      messageBtn?.classList?.remove("widget-zendesk--no-display");
      messagewindow?.classList?.remove("widget-zendesk--no-display");
      messageBtn2?.classList?.remove("widget-zendesk--no-display");
      messagewindow2?.classList?.remove("widget-zendesk--no-display");
      unreadMessages?.classList?.remove("widget-zendesk--no-display");
    } else {
      laucher?.classList?.add("widget-zendesk--no-display");
      laucherWindow?.[0]?.classList?.add("widget-zendesk--no-display");
      laucherWindow2?.[0]?.classList?.add("widget-zendesk--no-display");
      messageBtn?.classList?.add("widget-zendesk--no-display");
      messagewindow?.classList?.add("widget-zendesk--no-display");
      messageBtn2?.classList?.add("widget-zendesk--no-display");
      messagewindow2?.classList?.add("widget-zendesk--no-display");
      unreadMessages?.classList?.add("widget-zendesk--no-display");
    }
  }, [
    isAuth,
    hasZE,
    scriptLoaded,
    location?.pathname,
    zendeskJWT,
    auth.isLoggedIn,
    auth.status,
  ]);

  return <div id="widget-zendesk-id" className="widget-zendesk"></div>;
};
