import Popover from "@material-ui/core/Popover";
import React, { Fragment, useContext, useEffect, useState } from "react";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import CircularProgress from "@material-ui/core/CircularProgress";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearchDollar,
  faHandHoldingUsd,
} from "@fortawesome/free-solid-svg-icons";

import { ModalError } from "../../shared/components/UIElements/ModalError";
import { ModalInvalid } from "../../shared/components/UIElements/ModalInvalid";
import { useHttpClient } from "../../shared/hooks/httpHook";
import { useForm } from "../../shared/hooks/formHook";
import { Input } from "../../shared/components/FormElements/Input";
import { InputSelectRequired } from "../../shared/components/FormElements/InputSelectRequired";
import { InputCpf } from "../../shared/components/FormElements/InputCpf";
import { InputCnpj } from "../../shared/components/FormElements/InputCnpj";
import { InputPrefixOutlinedInt } from "../../shared/components/FormElements/InputPrefixOutlinedInt";
import { InputSwitchCustom } from "../../shared/components/FormElements/InputSwitchCustom";
import { InputPrefixOutlinedFloat } from "../../shared/components/FormElements/InputPrefixOutlinedFloat";
import { InputPostalCode } from "../../shared/components/FormElements/InputPostalCode";
import { InputAutocompleteCity } from "../../shared/components/FormElements/InputAutocompleteCity";
import { fetchPostalCodeInfo } from "../../general/api/signupPagesAPI";
import { SettingsContext } from "../../shared/context/settingsContext";
import { addThousandSeparator } from "../../shared/util/addThousandSeparator";
import { normalizeText } from "../../shared/util/normalizeText";
import { generateBTGCreditAnalysis } from "../api/financingsAPI";
import { fetchUserData } from "../../general/api/userAPI";
import { ButtonCyanContained } from "../../shared/components/MUIThemed/ButtonCyanContained";
import { ButtonDarkBlueContained } from "../../shared/components/MUIThemed/ButtonDarkBlueContained";
import {
  VALIDATOR_REQUIRE,
  VALIDATOR_MINLENGTH,
  VALIDATOR_EMAIL,
  VALIDATOR_MIN,
  VALIDATOR_VALID,
} from "../../shared/util/validators";
import { UF, SUPPLIER_CNPJ } from "../../shared/data/static";

import {
  AuthContextProps,
  SignatureData,
  KanbanFilledData,
  CustomerDataPopEst,
  CustomerData,
  UserData,
  ProposalData,
  PostalCodeResponseData,
  PlaceData,
  FinancingData,
} from "../../shared/data/types";

import { PopoverFormStyles } from "./PopoverFormStyles";
import { ProposalStepTwoKitChoiceStyles } from "../../proposal/pages/ProposalStepTwoKitChoiceStyles";

import "./PopoverAddBTGFinancing.scss";

interface PopoverAddBTGFinancingProps {
  anchorEl: HTMLButtonElement | HTMLLIElement | HTMLDivElement | null;
  setAnchorEl: React.Dispatch<
    React.SetStateAction<HTMLButtonElement | HTMLLIElement | HTMLDivElement>
  >;
  auth: AuthContextProps;
  cid: string;
  customer: CustomerDataPopEst | CustomerData;
  customerProposals: ProposalData[];
  setLoadedFinancings: React.Dispatch<React.SetStateAction<FinancingData[]>>;
  proposal?: ProposalData;
}

export const PopoverAddBTGFinancing = (props: PopoverAddBTGFinancingProps) => {
  const {
    anchorEl,
    setAnchorEl,
    auth,
    cid,
    customer,
    setLoadedFinancings,
    proposal,
    customerProposals,
  } = props;
  const { error, sendRequest, clearError, isLoading } = useHttpClient();
  const open = Boolean(anchorEl);
  const classes = PopoverFormStyles();
  const classes1 = ProposalStepTwoKitChoiceStyles();
  const settingsCtx = useContext(SettingsContext);
  const [loadedPlaces, setLoadedPlaces] = useState<PlaceData[]>(null);
  const [loadedUser, setLoadedUser] = useState<UserData>(null);
  const [chosenProp, setChosenProp] = useState<string>(
    customerProposals?.slice(-1)?.[0]?._id || "none"
  );
  const [chosenPropComplete, setChosenPropComplete] = useState<ProposalData>(
    customerProposals?.slice(-1)?.[0] || null
  );
  const [postalCodeSearchInfo, setPostalCodeSearchInfo] =
    useState<PostalCodeResponseData>(null);
  const [invalidInputs, setInvalidInputs] = useState<{
    showInvalid: boolean;
    invalidFields: string[];
  }>({
    showInvalid: false,
    invalidFields: [],
  });
  const [stage, setStage] = useState<"SIMULATION" | "DEAL">(null);
  const { formState, inputHandler, reportInvalid } = useForm({}, false);

  useEffect(() => {
    if (!!anchorEl) {
      fetchUserData({ sendRequest, setLoadedData: setLoadedUser, auth });
    }

    if (!anchorEl) {
      var timer = setTimeout(() => {
        setStage(null);
      }, 350);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [anchorEl]);

  useEffect(() => {
    if (customerProposals?.length > 0) {
      setChosenProp(customerProposals?.slice(-1)?.[0]?._id);
      setChosenPropComplete(customerProposals?.slice(-1)?.[0]);
    } else {
      setChosenProp("none");
      setChosenPropComplete(null);
    }
  }, [customerProposals]);

  useEffect(() => {
    if (!!chosenProp) {
      const foundProp = customerProposals?.find((p) => p._id === chosenProp);
      setChosenPropComplete(foundProp || null);
    }
  }, [chosenProp]);

  useEffect(() => {
    if (
      formState.inputs?.postalCode?.isValid &&
      formState.inputs?.postalCode?.value?.toString()?.replace(/\D/g, "")
        ?.length === 8
    ) {
      fetchPostalCodeInfo({
        postalCode: formState.inputs.postalCode.value as string,
        sendRequest,
        setPostalCodeSearchInfo,
      });
    }
    if (
      !formState.inputs?.postalCode?.isValid ||
      formState.inputs?.postalCode?.value?.toString()?.replace(/\D/g, "")
        ?.length !== 8
    ) {
      setPostalCodeSearchInfo(null);
    }
  }, [
    formState.inputs?.postalCode?.isValid,
    formState.inputs?.postalCode?.value,
    sendRequest,
  ]);

  const electricBillAverage = chosenPropComplete?.energyBillData
    ?.map(
      (e) => (e.monthlyConsumption?.reduce((a, b) => a + b) / 12) * e?.kwhPrice
    )
    ?.reduce((a, b) => a + b);

  const changePropValueHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChosenProp(e.target.value);
  };

  const reportInvalidHandler = () => {
    const whatIsInvalid = reportInvalid(formState.inputs);
    setInvalidInputs({ showInvalid: true, invalidFields: whatIsInvalid });
  };

  const confirmModalCloseHandler = () => {
    setInvalidInputs({ showInvalid: false, invalidFields: [] });
  };

  const initSupplier =
    SUPPLIER_CNPJ?.map((s) => s.value)?.find((s) =>
      chosenPropComplete?.realSystemData?.supplier
        ?.toUpperCase()
        ?.includes(s.toUpperCase())
    ) ||
    settingsCtx?.preferences?.suppliers?.stdSupplierName ||
    "";

  const supplierCNPJ =
    SUPPLIER_CNPJ?.find(
      (s) => s.value === formState?.inputs?.supplier?.value
    )?.cnpj?.replace(/\D/g, "") ||
    settingsCtx?.preferences?.suppliers?.stdSupplierCNPJ ||
    "";

  const addBTGFinancingHandler = () => {
    generateBTGCreditAnalysis({
      sendRequest,
      auth,
      formState,
      cid: cid,
      pid: chosenProp,
      setLoadedFinancings,
      stage,
    }).finally(() => {
      setAnchorEl(null);
    });
  };

  return (
    <Fragment>
      <ModalError error={error} onClear={clearError} />
      <ModalInvalid
        open={invalidInputs.showInvalid}
        closeHandler={confirmModalCloseHandler}
        message={invalidInputs.invalidFields.map((field) => {
          return <li key={field + "key"}>{field}</li>;
        })}
      />
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
        style={{ zIndex: 501 }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {!stage && (
          <div className="financing-popover__btn-choice">
            <Typography className={classes1.formTitle} variant="subtitle2">
              Deseja Simular ou Contratar?
            </Typography>
            <div className="financing-popover__btn-choice-btns">
              <ButtonDarkBlueContained
                className={classes1.choiceButton}
                variant="contained"
                color="primary"
                onClick={() => {
                  setStage("SIMULATION");
                }}
                style={{ marginTop: "20px" }}
              >
                SIMULAR
                <FontAwesomeIcon
                  icon={faSearchDollar}
                  className={classes1.btnIconsFontAwesome}
                />
              </ButtonDarkBlueContained>
              <ButtonCyanContained
                className={classes1.choiceButton}
                variant="contained"
                color="primary"
                onClick={() => {
                  setStage("DEAL");
                }}
                style={{ marginTop: "20px" }}
              >
                CONTRATAR
                <FontAwesomeIcon
                  icon={faHandHoldingUsd}
                  className={classes1.btnIconsFontAwesome}
                />
              </ButtonCyanContained>
            </div>
          </div>
        )}
        {!!stage && (
          <form style={{ zIndex: 502 }} className={classes.formRoot2}>
            <FormControl variant="outlined">
              <InputLabel
                style={{ backgroundColor: "#fff", padding: "0 0.4rem" }}
              >
                Selecionar proposta
              </InputLabel>
              <Select value={chosenProp} onChange={changePropValueHandler}>
                {customerProposals?.map((option, i) => {
                  return (
                    <MenuItem key={`opt-4-${i}`} value={option?.id}>
                      {`#${
                        option.count?.toLocaleString("pt-BR", {
                          minimumIntegerDigits: 5,
                          useGrouping: false,
                        }) || "000000"
                      } - ${addThousandSeparator(
                        option?.realSystemData?.power || 0
                      )} kWp - R$ ${addThousandSeparator(
                        option?.revenueData?.finalPrice || 0
                      )}`}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <div className="form-inputs-grid-1fr-1fr form-inputs-grid-responsive">
              <Input
                isName
                id="customerName"
                type="custom"
                label={
                  !formState.inputs?.cpfCnpjSwitch?.value
                    ? "Nome Completo do Cliente"
                    : "Razão Social do Cliente"
                }
                variant="outlined"
                validators={[VALIDATOR_REQUIRE()]}
                helperText="Insira o nome completo do cliente (letras e números somente)."
                initialValue={customer?.name || ""}
                initialValid={true}
                onInput={inputHandler}
                forceError={formState?.inputs?.customerName?.forceError}
              />
              <Input
                id="customerEmail"
                type="email"
                label="email"
                variant="outlined"
                validators={[VALIDATOR_EMAIL()]}
                helperText="Insira um e-mail válido para o cliente."
                initialValue={customer?.email || ""}
                initialValid={!!customer?.email}
                onInput={inputHandler}
                forceError={formState.inputs?.customerEmail?.forceError}
              />
            </div>
            <div className="form-inputs-grid-max-1fr">
              <InputSwitchCustom
                id="cpfCnpjSwitch"
                label={formState.inputs?.cpfCnpjSwitch?.value ? "CNPJ" : "CPF"}
                color="default"
                onInput={inputHandler}
                initialValue={
                  customer?.cpfCnpjSwitch ||
                  customer?.document?.replace(/\D/g, "")?.length > 11
                }
                title="Escolha CPF para pessoa física ou CNPJ para empresa."
              />
              {!formState.inputs?.cpfCnpjSwitch?.value && (
                <InputCpf
                  id="customerIdentification"
                  type="text"
                  label="CPF do Cliente"
                  variant="outlined"
                  validators={[VALIDATOR_MINLENGTH(11)]}
                  helperText="Insira o CPF do cliente."
                  onInput={inputHandler}
                  initialValue={customer?.document?.replace(/\D/g, "")}
                  initialValid={
                    customer?.document?.replace(/\D/g, "")?.length === 11
                  }
                  forceError={
                    formState?.inputs?.customerIdentification?.forceError
                  }
                />
              )}
              {formState.inputs?.cpfCnpjSwitch?.value && (
                <InputCnpj
                  id="customerIdentification"
                  type="text"
                  label="CNPJ do Cliente"
                  variant="outlined"
                  validators={[VALIDATOR_MINLENGTH(14)]}
                  helperText="Insira o CNPJ do cliente."
                  onInput={inputHandler}
                  initialValue={customer?.document?.replace(/\D/g, "")}
                  initialValid={
                    customer?.document?.replace(/\D/g, "")?.length === 14
                  }
                  forceError={
                    formState?.inputs?.customerIdentification?.forceError
                  }
                />
              )}
            </div>
            <div className="form-inputs-grid-1fr-1fr form-inputs-grid-responsive">
              <InputSelectRequired
                id="supplier"
                label="Fornecedor"
                onInput={inputHandler}
                initialValue={initSupplier || ""}
                options={[...SUPPLIER_CNPJ?.map((s) => s.value), "OUTRO"]}
                variant={"outlined"}
                updateInitValue
              />
              <InputCnpj
                id="distributorCnpj"
                type="text"
                label="CNPJ do Fornecedor"
                variant="outlined"
                validators={[VALIDATOR_MINLENGTH(14)]}
                helperText="Insira o CNPJ do fornecedor."
                onInput={inputHandler}
                initialValue={supplierCNPJ}
                initialValid={!!supplierCNPJ}
                forceError={formState?.inputs?.distributorCnpj?.forceError}
                reinitialize
              />
            </div>
            <InputCnpj
              id="integratorCnpj"
              type="text"
              label="CNPJ do Integrador"
              variant="outlined"
              validators={[VALIDATOR_MINLENGTH(14)]}
              helperText="Insira o CNPJ do distribuidor."
              onInput={inputHandler}
              initialValue={loadedUser?.cnpj?.replace(/\D/g, "") || ""}
              initialValid={false}
              reinitialize
              forceError={formState?.inputs?.integratorCnpj?.forceError}
            />
            <div className="form-inputs-grid-1fr-1fr form-inputs-grid-responsive">
              <InputPrefixOutlinedFloat
                id="monthlyBilling"
                type="text"
                label="Renda Mensal do Cliente"
                variant="outlined"
                prefix="R$"
                helperText="Insira o valor da renda mensal do cliente."
                validators={[VALIDATOR_MIN(0.01)]}
                initialValue={
                  !!customer.income ? customer.income?.toString() : ""
                }
                initialValid={!!customer.income}
                onInput={inputHandler}
                forceError={formState.inputs?.monthlyBilling?.forceError}
                updateInitValue
              />
              <InputPrefixOutlinedFloat
                id="electricBillAverage"
                type="text"
                label="Conta de Energia (mês)"
                variant="outlined"
                prefix="R$"
                helperText="Insira o valor da conta de energia no mês."
                validators={[VALIDATOR_MIN(0.01)]}
                initialValue={electricBillAverage?.toString() || ""}
                initialValid={!!electricBillAverage}
                onInput={inputHandler}
                forceError={formState.inputs?.electricBillAverage?.forceError}
                updateInitValue
              />
            </div>
            <div className="form-inputs-grid-1fr-1fr form-inputs-grid-responsive">
              <InputPrefixOutlinedFloat
                id="projectValue"
                type="text"
                label="Valor do Projeto"
                variant="outlined"
                prefix="R$"
                helperText="Insira o valor do projeto."
                validators={[VALIDATOR_MIN(0.01)]}
                initialValue={
                  chosenPropComplete?.revenueData?.finalPrice?.toString() || ""
                }
                initialValid={!!chosenPropComplete?.revenueData?.finalPrice}
                onInput={inputHandler}
                forceError={formState.inputs?.projectValue?.forceError}
                updateInitValue
              />
              <InputPostalCode
                id="cep"
                type="text"
                label="CEP"
                variant="outlined"
                validators={[VALIDATOR_MINLENGTH(8)]}
                helperText="Insira um CEP válido."
                onInput={inputHandler}
                initialValue={customer.postalCode || ""}
                initialValid={true}
                forceError={formState.inputs?.postalCode?.forceError}
              />
            </div>
            <div className="form-inputs-grid-70px-1fr">
              <InputSelectRequired
                id="state"
                label="UF"
                onInput={inputHandler}
                initialValue={
                  postalCodeSearchInfo?.uf?.toUpperCase() || customer?.uf || ""
                }
                options={UF}
                disabled={
                  formState?.inputs?.city?.value &&
                  formState?.inputs?.city?.value.toString().length > 0
                    ? true
                    : false
                }
                variant={
                  formState?.inputs?.city?.value &&
                  formState?.inputs?.city?.value.toString().length > 0
                    ? "filled"
                    : "standard"
                }
                updateInitValue
              />
              <InputAutocompleteCity
                disabled={!formState?.inputs?.state?.value}
                // options={loadedPlaces}
                setLoadedPlaces={setLoadedPlaces}
                helperText="Escolha uma cidade válida."
                uf={formState?.inputs?.state?.value?.toString()?.toLowerCase()}
                id="city"
                label="cidade"
                variant="outlined"
                initialValue={
                  postalCodeSearchInfo?.localidade
                    ? `${normalizeText(
                        postalCodeSearchInfo?.localidade
                      )?.toUpperCase()} - ${postalCodeSearchInfo?.uf?.toUpperCase()}`
                    : chosenPropComplete?.location?.city || customer?.city || ""
                }
                onInput={inputHandler}
                updateInitValue
              />
            </div>
            <Input
              isMessage
              id="address"
              type="text"
              label="Logradouro"
              variant="outlined"
              validators={[VALIDATOR_REQUIRE()]}
              helperText="Insira um logradouro válido."
              onInput={inputHandler}
              initialValue={
                postalCodeSearchInfo?.logradouro ||
                customer?.addressDetail?.street ||
                ""
              }
              initialValid
              reinitialize
              forceError={formState.inputs?.street?.forceError}
            />
            <div className="form-inputs-grid-125px-1fr">
              <InputPrefixOutlinedInt
                id="number"
                type="text"
                label="número"
                variant="outlined"
                prefix="Nº"
                validators={[VALIDATOR_REQUIRE()]}
                helperText="Insira um número válido."
                onInput={inputHandler}
                initialValue={customer?.addressDetail?.number || ""}
                initialValid
                forceError={formState.inputs?.number?.forceError}
              />
              <Input
                isMessage
                id="district"
                type="text"
                label="bairro"
                variant="outlined"
                validators={[VALIDATOR_REQUIRE()]}
                helperText="Insira um nome de bairro válido."
                onInput={inputHandler}
                initialValue={
                  postalCodeSearchInfo?.bairro ||
                  customer?.addressDetail?.district ||
                  ""
                }
                initialValid
                forceError={formState.inputs?.district?.forceError}
                reinitialize
              />
            </div>
            <Input
              isMessage
              id="complement"
              type="text"
              label="Complemento"
              variant="outlined"
              validators={[VALIDATOR_VALID()]}
              helperText="Insira um complemento válido."
              onInput={inputHandler}
              initialValue={
                postalCodeSearchInfo?.complemento ||
                customer?.addressDetail?.complement ||
                ""
              }
              initialValid
              forceError={formState.inputs?.district?.forceError}
              reinitialize
            />
            {!isLoading && formState.isValid && (
              <Button
                variant="contained"
                color="primary"
                disabled={!formState.isValid}
                onClick={addBTGFinancingHandler}
              >
                SOLICITAR ANÁLISE DE CRÉDITO
              </Button>
            )}
            {!isLoading && !formState.isValid && (
              <Button
                variant="outlined"
                color="secondary"
                onClick={reportInvalidHandler}
              >
                O QUE HÁ DE ERRADO?
              </Button>
            )}
            {isLoading && (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "1.5rem",
                }}
              >
                <CircularProgress color="primary" />
              </div>
            )}
          </form>
        )}
      </Popover>
    </Fragment>
  );
};
