import Popover from "@material-ui/core/Popover";
import React, { Fragment, useContext, useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

import { ModalError } from "../../shared/components/UIElements/ModalError";
import { ModalInvalid } from "../../shared/components/UIElements/ModalInvalid";
import { useHttpClient } from "../../shared/hooks/httpHook";
import { useForm } from "../../shared/hooks/formHook";
import { Input } from "../../shared/components/FormElements/Input";
import { InputCnpj } from "../../shared/components/FormElements/InputCnpj";
import { InputPhone } from "../../shared/components/FormElements/InputPhone";
import { SettingsContext } from "../../shared/context/settingsContext";
import { submitUserRegistration } from "../api/financingsAPI";
import { fetchUserData } from "../../general/api/userAPI";
import {
  VALIDATOR_REQUIRE,
  VALIDATOR_MINLENGTH,
  VALIDATOR_EMAIL,
} from "../../shared/util/validators";
import {
  AuthContextProps,
  UserData,
  RegistrationData,
} from "../../shared/data/types";

import { PopoverFormStyles } from "./PopoverFormStyles";

interface PopoverAddBTGRegistrationRequestProps {
  anchorEl: HTMLButtonElement | HTMLLIElement | HTMLDivElement | null;
  setAnchorEl: React.Dispatch<
    React.SetStateAction<HTMLButtonElement | HTMLLIElement | HTMLDivElement>
  >;
  auth: AuthContextProps;
  setLoadedRegistration: React.Dispatch<React.SetStateAction<RegistrationData>>;
}

export const PopoverAddBTGRegistrationRequest = (
  props: PopoverAddBTGRegistrationRequestProps
) => {
  const { anchorEl, setAnchorEl, auth, setLoadedRegistration } = props;
  const { error, sendRequest, clearError, isLoading } = useHttpClient();
  const open = Boolean(anchorEl);
  const classes = PopoverFormStyles();
  const settingsCtx = useContext(SettingsContext);
  const [loadedUser, setLoadedUser] = useState<UserData>(null);
  const [invalidInputs, setInvalidInputs] = useState<{
    showInvalid: boolean;
    invalidFields: string[];
  }>({
    showInvalid: false,
    invalidFields: [],
  });
  const { formState, inputHandler, reportInvalid } = useForm({}, false);

  useEffect(() => {
    if (!!anchorEl) {
      fetchUserData({ sendRequest, setLoadedData: setLoadedUser, auth });
    }
  }, [anchorEl]);

  const reportInvalidHandler = () => {
    const whatIsInvalid = reportInvalid(formState.inputs);
    setInvalidInputs({ showInvalid: true, invalidFields: whatIsInvalid });
  };

  const confirmModalCloseHandler = () => {
    setInvalidInputs({ showInvalid: false, invalidFields: [] });
  };

  const sendRegitrationRequestHandler = () => {
    submitUserRegistration({
      sendRequest,
      auth,
      formState,
      setLoadedRegistration,
    }).finally(() => {
      setAnchorEl(null);
    });
  };

  return (
    <Fragment>
      <ModalError error={error} onClear={clearError} />
      <ModalInvalid
        open={invalidInputs.showInvalid}
        closeHandler={confirmModalCloseHandler}
        message={invalidInputs.invalidFields.map((field) => {
          return <li key={field + "key"}>{field}</li>;
        })}
      />
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={() => {
          setAnchorEl(null);
        }}
        style={{ zIndex: 501 }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <form style={{ zIndex: 502 }} className={classes.formRoot}>
          <Input
            isMessage
            id="corporateName"
            type="text"
            label="Razão Social"
            variant="outlined"
            validators={[VALIDATOR_REQUIRE()]}
            helperText="Insira a sua Razão Social."
            initialValue={""}
            initialValid={false}
            onInput={inputHandler}
            forceError={formState?.inputs?.corporateName?.forceError}
            reinitialize
          />
          <InputCnpj
            id="cnpj"
            type="text"
            label="CNPJ"
            variant="outlined"
            validators={[VALIDATOR_MINLENGTH(14)]}
            helperText="Insira o seu CNPJ."
            onInput={inputHandler}
            initialValue={loadedUser?.cnpj?.replace(/\D/g, "") || ""}
            initialValid={loadedUser?.cnpj?.replace(/\D/g, "")?.length === 14}
            disabled={loadedUser?.cnpj?.replace(/\D/g, "")?.length === 14}
            forceError={formState?.inputs?.cnpj?.forceError}
            reinitialize
          />
          <Input
            isMessage
            id="name"
            type="text"
            label="Nome do Responsável"
            variant="outlined"
            validators={[VALIDATOR_REQUIRE()]}
            helperText="Insira o nome do responsável."
            initialValue={loadedUser?.personInCharge?.name || ""}
            initialValid={!!loadedUser?.personInCharge?.name}
            onInput={inputHandler}
            forceError={formState?.inputs?.name?.forceError}
            reinitialize
          />
          <Input
            id="email"
            type="email"
            label="email"
            variant="outlined"
            validators={[VALIDATOR_EMAIL()]}
            helperText="Insira um e-mail válido."
            initialValue={loadedUser?.email || ""}
            initialValid={!!loadedUser?.email}
            disabled
            onInput={inputHandler}
            forceError={formState.inputs?.email?.forceError}
            reinitialize
          />
          <InputPhone
            id="phone"
            type="text"
            label="celular"
            variant="outlined"
            validators={[VALIDATOR_MINLENGTH(10)]}
            helperText="Insira um número válido."
            initialValue={loadedUser?.whatsapp || loadedUser?.phone || ""}
            initialValid={
              (loadedUser?.whatsapp || loadedUser?.phone || "")?.replace(
                /\D/g,
                ""
              )?.length >= 10
            }
            onInput={inputHandler}
            reset={formState.reset}
            forceError={formState.inputs?.phone?.forceError}
            reinitialize
          />
          {!isLoading && formState.isValid && (
            <Button
              variant="contained"
              color="primary"
              disabled={!formState.isValid}
              onClick={sendRegitrationRequestHandler}
            >
              SOLICITAR CADASTRO NO BTG
            </Button>
          )}
          {!isLoading && !formState.isValid && (
            <Button
              variant="outlined"
              color="secondary"
              onClick={reportInvalidHandler}
            >
              O QUE HÁ DE ERRADO?
            </Button>
          )}
          {isLoading && (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                marginTop: "1.5rem",
              }}
            >
              <CircularProgress color="primary" />
            </div>
          )}
        </form>
      </Popover>
    </Fragment>
  );
};
