import { Fragment, useState } from "react";
import IconButton from "@material-ui/core/IconButton";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import Collapse from "@material-ui/core/Collapse";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import PostAddRoundedIcon from "@material-ui/icons/PostAddRounded";
import MonetizationOnRoundedIcon from "@material-ui/icons/MonetizationOnRounded";

import { useHttpClient } from "../../shared/hooks/httpHook";
import { ModalError } from "../../shared/components/UIElements/ModalError";
import { PopoverAddFinToProposal } from "./PopoverAddFinToProposal";
import { SaleDashFinacingProposalItem } from "./SaleDashFinacingProposalItem";
import { addThousandSeparator } from "../../shared/util/addThousandSeparator";
import { LoadingSpinnerOverlayRegular } from "../../shared/components/UIElements/LoadingSpinnerOverlayRegular";
import { removeDuplicateObjectsFromArray } from "../../shared/util/removeDuplicateObjectsFromArray";
import { openWhatsapp } from "../../shared/util/openWhatsapp";
import { editFinancingStage } from "../api/financingsAPI";
import { BTG_STATUS_MAP } from "../../shared/data/static";
import {
  FinancingData,
  AuthContextProps,
  KanbanFilledData,
} from "../../shared/data/types";

import "./SaleDashNoteItem.scss";

interface SaleDashFinacingItemProps {
  auth: AuthContextProps;
  customerId: string;
  loadedFinancing: FinancingData;
  setLoadedFinancings: React.Dispatch<React.SetStateAction<FinancingData[]>>;
}

export const SaleDashFinacingItem = (props: SaleDashFinacingItemProps) => {
  const { loadedFinancing, auth, customerId, setLoadedFinancings } = props;
  const { sendRequest, isLoading, error, clearError } = useHttpClient();
  const [open, setOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>(null);
  const [anchorElForm, setAnchorElForm] = useState<
    null | HTMLLIElement | HTMLButtonElement
  >(null);

  const financingProposals = loadedFinancing?.proposals?.length
    ? loadedFinancing?.proposals
    : ([
        {
          bank: "BTG",
          installments: 0,
          installmentValue: 0,
          gracePeriod: 0,
          entry: 0,
          fee: 0,
          feeYearly: 0,
          totalFinanced: 0,
          totalPayment: 0,
        },
      ] as FinancingData["proposals"]);

  const uniqueBTGFinProps =
    removeDuplicateObjectsFromArray<FinancingData["proposals"][0]>(
      financingProposals?.filter((p) => p.bank === "BTG"),
      "totalPayment"
    ) || [];

  const uniqueFinProps = uniqueBTGFinProps;

  const foundStatus =
    loadedFinancing?.stage === "DEAL"
      ? BTG_STATUS_MAP?.find(
          (s) => s.status === loadedFinancing?.btg?.status
        ) || BTG_STATUS_MAP?.[0]
      : {
          status: "SIMULAÇÃO_GERADA",
          color: loadedFinancing?.btg?.preApproved
            ? "positive_light"
            : "neutral",
          txt: loadedFinancing?.btg?.preApproved
            ? "Simulação Pré-Aprovada"
            : "Simulação",
        };

  const openWhatsappHandler = () => {
    openWhatsapp(
      "31992330282",
      "Quero contratar um financiamento pré-aprovado."
    );
  };

  const editFinancingStageHandler = () => {
    editFinancingStage({
      sendRequest,
      auth,
      fid: loadedFinancing?._id,
      setLoadedFinancings,
    }).then(() => {
      openWhatsapp(
        "31992330282",
        "Quero contratar um financiamento pré-aprovado."
      );
    });
  };

  return (
    <Fragment>
      <div
        className={`sale-prop-item`}
        onClick={(e) => {
          if (!anchorEl && !anchorElForm) {
            setOpen(!open);
          }
        }}
      >
        <PopoverAddFinToProposal
          anchorEl={anchorElForm}
          setAnchorEl={setAnchorElForm}
          auth={auth}
          financing={loadedFinancing}
          uniqueFinProps={uniqueFinProps}
        />
        <ModalError error={error} onClear={clearError} />
        {isLoading && <LoadingSpinnerOverlayRegular image color="primary" />}
        <div className="sale-prop-note-item__data">
          <p className="sale-prop-note-item__data-item sale-prop-note-item__data-item--thin">
            Proposta:{" "}
            {`#${
              loadedFinancing.proposal?.count?.toLocaleString("pt-BR", {
                minimumIntegerDigits: 5,
                useGrouping: false,
              }) || "000000"
            } - ${addThousandSeparator(
              loadedFinancing.proposal?.realSystemData?.power || 0
            )} kWp - R$ ${addThousandSeparator(
              loadedFinancing.proposal?.revenueData?.finalPrice || 0
            )}`}
          </p>
        </div>
        <div className="sale-prop-note-item__controls">
          <IconButton
            size="small"
            title={open ? "Ver Mais" : "Fechar"}
            className={`table__item-action${open ? "--open" : ""}`}
            onClick={(e) => {
              if (!anchorEl && !anchorElForm) {
                setOpen(!open);
                e.stopPropagation();
              }
            }}
          >
            <ExpandMoreRoundedIcon style={{ fontSize: "22px" }} />
          </IconButton>
          <IconButton
            size="small"
            title="Adicionar à proposta"
            className="table__item-action"
            onClick={(e) => {
              e.stopPropagation();
              setAnchorElForm(e.currentTarget);
            }}
          >
            <PostAddRoundedIcon style={{ fontSize: "22px" }} />
          </IconButton>
        </div>
        <div className="sale-prop-note-item__data sale-prop-note-item__data--full-width">
          <p
            className={`sale-prop-note-item__data-item sale-prop-note-item__data-item--thin u-margin-top-sm`}
          >
            <b>
              {loadedFinancing?.stage !== "DEAL" ? "SIMULAÇÃO" : "CONTRATAÇÃO"}{" "}
              BTG
              <span className="sale-prop-note-item__txt-highlight">
                {loadedFinancing?.btg?.preApproved ? " - PRÉ-APROVADO" : ""}
              </span>
            </b>
          </p>
          <p
            className={`sale-prop-note-item__status sale-prop-note-item__status--${
              foundStatus.color || "positive_dark"
            }`}
          >
            {foundStatus?.txt || ""}
          </p>
          {loadedFinancing?.stage !== "DEAL" && (
            <div
              onClick={(e) => {
                e.stopPropagation();
                if (loadedFinancing?.btg?.preApproved) {
                  editFinancingStageHandler();
                }
              }}
              className={`sale-prop-note-item__whatsapp-cta sale-prop-note-item__whatsapp-cta--light ${
                !loadedFinancing?.btg?.preApproved
                  ? "sale-prop-note-item__whatsapp-cta--disabled"
                  : ""
              }`}
            >
              <MonetizationOnRoundedIcon />
              &nbsp; QUERO CONTRATAR
            </div>
          )}
          {loadedFinancing?.stage === "DEAL" && (
            <div
              onClick={(e) => {
                e.stopPropagation();
                if (loadedFinancing?.btg?.preApproved) {
                  openWhatsappHandler();
                }
              }}
              className={`sale-prop-note-item__whatsapp-cta ${
                !loadedFinancing?.btg?.preApproved
                  ? "sale-prop-note-item__whatsapp-cta--disabled"
                  : ""
              }`}
            >
              <WhatsAppIcon />
              &nbsp; CONTRATAR
            </div>
          )}
        </div>
        <div className="sale-prop-note-item__details">
          <Collapse in={open}>
            <div className="sale-prop-note-item__details-content">
              {uniqueFinProps?.map((p, i) => {
                return (
                  <SaleDashFinacingProposalItem
                    financingProposal={p}
                    financing={loadedFinancing}
                    index={i}
                    key={`financing-prop-${i}`}
                  />
                );
              })}
            </div>
            <div className="sale-prop-note-item__details-content">
              <p className="sale-prop-note-item__data-item sale-prop-note-item__data-item--thin">
                Atualizado em: <span>{loadedFinancing?.lastUpdated || ""}</span>
              </p>
              <p className="sale-prop-note-item__data-item sale-prop-note-item__data-item--thin">
                Registro: <span>{loadedFinancing?.registryDate || ""}</span>
              </p>
            </div>
          </Collapse>
        </div>
      </div>
    </Fragment>
  );
};
