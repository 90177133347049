import { Fragment } from "react";

import { addThousandSeparator } from "../../shared/util/addThousandSeparator";
import { BTG_STATUS_MAP } from "../../shared/data/static";
import { FinancingData } from "../../shared/data/types";

interface SaleDashFinacingProposalItemProps {
  index: number;
  financingProposal: FinancingData["proposals"][0];
  financing: FinancingData;
}

export const SaleDashFinacingProposalItem = (
  props: SaleDashFinacingProposalItemProps
) => {
  const { financingProposal, index, financing } = props;

  const foundStatus =
    financingProposal?.bank === "BTG"
      ? BTG_STATUS_MAP?.find((s) => s.status === financing?.btg?.status) ||
        BTG_STATUS_MAP?.[0]
      : BTG_STATUS_MAP?.[0];

  return (
    <Fragment>
      <p
        className={`sale-prop-note-item__data-item sale-prop-note-item__data-item--thin ${
          index > 0 ? "u-margin-top-sm" : ""
        }`}
      >
        <b>{financingProposal?.bank || ""}</b>
      </p>
      {/* <p
        className={`sale-prop-note-item__status sale-prop-note-item__status--${
          foundStatus.color || "positive_dark"
        }`}
      >
        {foundStatus?.txt || ""}
      </p> */}
      <p className="sale-prop-note-item__data-item sale-prop-note-item__data-item--thin">
        Total Financiado: R${" "}
        {addThousandSeparator(financingProposal.totalFinanced || 0)}
      </p>
      <p className="sale-prop-note-item__data-item sale-prop-note-item__data-item--thin">
        Parcelas: x
        {addThousandSeparator(
          financingProposal.installments || 0,
          false,
          false
        )}
      </p>
      <p className="sale-prop-note-item__data-item sale-prop-note-item__data-item--thin">
        Valor da Parcela: R${" "}
        {addThousandSeparator(financingProposal.installmentValue || 0)}
      </p>
      <p className="sale-prop-note-item__data-item sale-prop-note-item__data-item--thin">
        Entrada: R$ {addThousandSeparator(financingProposal?.entry || 0)}
      </p>
      <p className="sale-prop-note-item__data-item sale-prop-note-item__data-item--thin">
        Carência:{" "}
        {addThousandSeparator(financingProposal.gracePeriod || 0, false, false)}{" "}
        Meses
      </p>
      <p className="sale-prop-note-item__data-item sale-prop-note-item__data-item--thin">
        Taxa a.m.: {addThousandSeparator(financingProposal.fee * 100 || 0)} %
      </p>
      <p className="sale-prop-note-item__data-item sale-prop-note-item__data-item--thin">
        Taxa a.a.: {addThousandSeparator(financingProposal.feeYearly || 0)} %
      </p>
      <p className="sale-prop-note-item__data-item sale-prop-note-item__data-item--thin">
        Pgto Total: R${" "}
        {addThousandSeparator(financingProposal.totalPayment || 0)}
      </p>
    </Fragment>
  );
};
