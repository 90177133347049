import { Fragment, useState, useEffect, useContext } from "react";

import { addThousandSeparator } from "../../shared/util/addThousandSeparator";
import { setMonthlyGenerationData } from "../../shared/data/dynamic";
import { SettingsContext } from "../../shared/context/settingsContext";
import { AuthContextProps, ProposalData } from "../../shared/data/types";

import "./PropDash.scss";

interface PropDashPricingProps {
  auth: AuthContextProps;
  memory: ProposalData;
}

export const PropDashPricing = (props: PropDashPricingProps) => {
  const { memory } = props;
  const settingsCtx = useContext(SettingsContext);

  const tradingMargin =
    settingsCtx.autoPricingDetailed?.pricingComponents?.find((comp) => {
      return comp.type === "TRADING_MARGIN";
    });

  const hasTradingMargin = !!tradingMargin;
  const isMarkup =
    settingsCtx.autoPricingDetailed?.autoPricingCalc === "MARKUP";

  return (
    <Fragment>
      {!memory?.finished && (
        <p className="cust-dash__col-right-content-empty">
          Esta proposta não possui dados do preço
        </p>
      )}
      {memory?.finished && (
        <div className="cust-dash__project">
          <Fragment>
            <p
              className="cust-dash__project-title"
              style={{
                color: "#263351",
                fontSize: "1.1rem",
                fontWeight: 700,
              }}
            >
              Precificação
            </p>
            <p
              className="cust-dash__project-title"
              style={{
                margin: "0 0 0.75rem",
                color: "#263351",
                fontSize: "1rem",
                fontWeight: 700,
              }}
            >
              Custos Fixos
            </p>
            <div className="cust-dash__project-grid">
              <p className="cust-dash__project-grid-item cust-dash__project-grid-item--label">
                Kit Fotovoltaico:
              </p>{" "}
              <p className="cust-dash__project-grid-item">
                {`R$ ${addThousandSeparator(
                  memory.revenueData?.pvKitOriginalCost ||
                    memory.revenueData?.pvKitCost
                )}`}
              </p>
              {memory.revenueData?.serviceCosts
                ?.filter((svcCost) => {
                  return !svcCost.isRevenue;
                })
                ?.map((svcCost, i) => {
                  return (
                    <Fragment key={`svc-cost-frag-${i}`}>
                      <p
                        key={`svc-cost-title-${i}`}
                        className="cust-dash__project-grid-item cust-dash__project-grid-item--label"
                      >
                        {svcCost.name}:
                      </p>
                      <p
                        key={`svc-cost-value-${i}`}
                        className="cust-dash__project-grid-item"
                      >
                        {`R$ ${addThousandSeparator(svcCost.value)}`}
                      </p>
                    </Fragment>
                  );
                })}
            </div>
            {memory.revenueData?.percentageComponents?.filter((svcCost) => {
              return !svcCost.isRevenue;
            })?.length > 0 && (
              <p
                className="cust-dash__project-title"
                style={{
                  margin: "1.5rem 0 0.75rem",
                  color: "#263351",
                  fontSize: "1rem",
                  fontWeight: 700,
                }}
              >
                Custos Variáveis
              </p>
            )}
            <div className="cust-dash__project-grid">
              {memory.revenueData?.percentageComponents
                ?.filter((pc) => {
                  return (
                    !pc.isRevenue ||
                    pc.type === "COMMISSION" ||
                    pc.type === "INDIVIDUAL_COMMISSION" ||
                    pc.type === "TRADING_MARGIN"
                  );
                })
                ?.map((pc, i) => {
                  return (
                    <Fragment key={`svc-var-cost-frag-${i}`}>
                      <p
                        key={`svc-var-cost-title-${i}`}
                        className="cust-dash__project-grid-item cust-dash__project-grid-item--label"
                      >
                        {pc.name}:
                      </p>
                      <p
                        key={`svc-var-cost-value-${i}`}
                        className="cust-dash__project-grid-item"
                      >
                        {`${addThousandSeparator(pc.value)} % ${
                          pc.type === "COMMISSION" ||
                          pc.type === "INDIVIDUAL_COMMISSION" ||
                          pc.type === "TRADING_MARGIN" ||
                          pc.type === "PERCENTAGE_SERVICE_AND_KIT"
                            ? `(sobre o valor final) - R$ ${addThousandSeparator(
                                (pc.value / 100) *
                                  (isMarkup
                                    ? memory?.revenueData?.finalPrice
                                    : memory?.revenueData?.finalPrice || 0)
                              )}`
                            : pc.type === "PERCENTAGE_KIT"
                            ? `(sobre o kit) - R$ ${addThousandSeparator(
                                (pc.value / 100) *
                                  (isMarkup
                                    ? memory?.revenueData?.pvKitCost
                                    : memory?.revenueData?.pvKitCost || 0)
                              )}`
                            : pc.type === "PERCENTAGE_SERVICE"
                            ? `(sobre o serviço) - R$ ${addThousandSeparator(
                                (pc.value / 100) *
                                  (isMarkup
                                    ? memory?.revenueData?.totalSvc
                                    : memory?.revenueData?.totalSvc || 0)
                              )}`
                            : pc.type === "PERCENTAGE_PROFIT"
                            ? `(sobre o lucro) - R$ ${addThousandSeparator(
                                (pc.value / 100) *
                                  memory?.revenueData?.calculatedData
                                    ?.preTaxProfit || 0
                              )}`
                            : ""
                        }`}
                      </p>
                    </Fragment>
                  );
                })}
            </div>
            {memory.revenueData?.serviceCosts?.filter((svcCost) => {
              return svcCost.isRevenue;
            })?.length > 0 && (
              <p
                className="cust-dash__project-title"
                style={{
                  margin: "1.5rem 0 0.75rem",
                  color: "#263351",
                  fontSize: "1rem",
                  fontWeight: 700,
                }}
              >
                Receitas Fixas
              </p>
            )}
            <div className="cust-dash__project-grid">
              {memory.revenueData?.serviceCosts
                ?.filter((svcCost) => {
                  return svcCost.isRevenue;
                })
                ?.map((svcCost, i) => {
                  return (
                    <Fragment key={`svc-rev-frag-${i}`}>
                      <p
                        key={`svc-rev-title-${i}`}
                        className="cust-dash__project-grid-item cust-dash__project-grid-item--label"
                      >
                        {svcCost.name}:
                      </p>
                      <p
                        key={`svc-rev-value-${i}`}
                        className="cust-dash__project-grid-item"
                      >
                        {`R$ ${addThousandSeparator(svcCost.value)}`}
                      </p>
                    </Fragment>
                  );
                })}
            </div>
            {memory.revenueData?.percentageComponents?.filter((pc) => {
              return pc.isRevenue;
            })?.length > 0 && (
              <p
                className="cust-dash__project-title"
                style={{
                  margin: "1.5rem 0 0.75rem",
                  color: "#263351",
                  fontSize: "1rem",
                  fontWeight: 700,
                }}
              >
                Receitas Variáveis
              </p>
            )}
            <div className="cust-dash__project-grid">
              {memory.revenueData?.percentageComponents
                ?.filter((pc) => {
                  return pc.isRevenue;
                })
                ?.map((pc, i) => {
                  return (
                    <Fragment key={`svc-var-rev-frag-${i}`}>
                      <p
                        key={`svc-var-rev-title-${i}`}
                        className="cust-dash__project-grid-item cust-dash__project-grid-item--label"
                      >
                        {pc.name}:
                      </p>
                      <p
                        key={`svc-var-rev-value-${i}`}
                        className="cust-dash__project-grid-item"
                      >
                        {`${addThousandSeparator(pc.value)} % ${
                          pc.type === "PERCENTAGE_SERVICE_AND_KIT"
                            ? `(sobre o valor final) - R$ ${addThousandSeparator(
                                (pc.value / 100) *
                                  (isMarkup
                                    ? memory?.revenueData?.finalPrice
                                    : memory?.revenueData?.finalPrice || 0)
                              )}`
                            : pc.type === "PERCENTAGE_KIT"
                            ? `(sobre o kit) - R$ ${addThousandSeparator(
                                (pc.value / 100) *
                                  (isMarkup
                                    ? memory?.revenueData?.pvKitCost
                                    : memory?.revenueData?.pvKitCost || 0)
                              )}`
                            : pc.type === "PERCENTAGE_SERVICE"
                            ? `(sobre o serviço) - R$ ${addThousandSeparator(
                                (pc.value / 100) *
                                  (isMarkup
                                    ? memory?.revenueData?.totalSvc
                                    : memory?.revenueData?.totalSvc || 0)
                              )}`
                            : pc.type === "PERCENTAGE_PROFIT"
                            ? `(sobre o serviço) - R$ ${addThousandSeparator(
                                (pc.value / 100) *
                                  memory?.revenueData?.calculatedData
                                    ?.preTaxProfit || 0
                              )}`
                            : ""
                        }`}
                      </p>
                    </Fragment>
                  );
                })}
            </div>
            {hasTradingMargin && !!memory.revenueData?.tradingMargin && (
              <p
                className="cust-dash__project-title"
                style={{
                  margin: "1.5rem 0 0.75rem",
                  color: "#263351",
                  fontSize: "1rem",
                  fontWeight: 700,
                }}
              >
                Margem de Negociação
              </p>
            )}
            {hasTradingMargin && !!memory.revenueData?.tradingMargin && (
              <Fragment>
                <div className="cust-dash__project-grid">
                  <p className="cust-dash__project-grid-item cust-dash__project-grid-item--label">
                    Margem de Negociação:
                  </p>
                  <p className="cust-dash__project-grid-item">
                    {`${addThousandSeparator(
                      memory.revenueData?.tradingMargin || 0
                    )} % (sobre o valor final) - R$ ${addThousandSeparator(
                      (memory.revenueData?.tradingMargin / 100) *
                        memory?.revenueData?.finalPrice || 0
                    )}`}
                  </p>
                </div>
              </Fragment>
            )}
            {hasTradingMargin && !!memory.revenueData?.discount && (
              <p
                className="cust-dash__project-title"
                style={{
                  margin: "1.5rem 0 0.75rem",
                  color: "#263351",
                  fontSize: "1rem",
                  fontWeight: 700,
                }}
              >
                Desconto de Negociação
              </p>
            )}
            {hasTradingMargin && !!memory.revenueData?.discount && (
              <Fragment>
                <div className="cust-dash__project-grid">
                  <p className="cust-dash__project-grid-item cust-dash__project-grid-item--label">
                    Desconto de Negociação:
                  </p>
                  <p className="cust-dash__project-grid-item">
                    R$ {addThousandSeparator(memory.revenueData?.discount)}
                  </p>
                </div>
              </Fragment>
            )}
            <p
              className="cust-dash__project-title"
              style={{
                margin: "1.5rem 0 0.75rem",
                color: "#263351",
                fontSize: "1rem",
                fontWeight: 700,
              }}
            >
              Receita Bruta Adicional
            </p>
            <div className="cust-dash__project-grid">
              <p className="cust-dash__project-grid-item cust-dash__project-grid-item--label">
                Receita Bruta Adicional:
              </p>{" "}
              <p className="cust-dash__project-grid-item">
                {`R$ ${addThousandSeparator(
                  memory.revenueData?.equipamentProfit
                )}`}
              </p>
              <p className="cust-dash__project-grid-item cust-dash__project-grid-item--label">
                Margem de Negociação:
              </p>{" "}
              <p className="cust-dash__project-grid-item">
                {`R$ ${addThousandSeparator(
                  (isMarkup
                    ? (memory.revenueData?.finalPrice *
                        memory.revenueData?.effMargin) /
                        100 || 0
                    : memory.revenueData?.negMarginValue) ||
                    (memory.revenueData?.tradingMargin / 100) *
                      (isMarkup
                        ? memory.revenueData?.finalPrice
                        : memory.revenueData?.finalPrice) ||
                    0
                )}`}
              </p>
            </div>
            <p
              className="cust-dash__project-title cust-dash__project-title--highlighted"
              style={{
                margin: "1.5rem 0 0.75rem",
                fontSize: "1rem",
                fontWeight: 700,
              }}
            >
              Valores Calculados
            </p>
            <div className="cust-dash__project-grid">
              <p className="cust-dash__project-grid-item cust-dash__project-grid-item--label">
                Total Equipamento:
              </p>{" "}
              <p className="cust-dash__project-grid-item">
                {`R$ ${addThousandSeparator(memory.revenueData?.totalEqp)}`}
              </p>
              <p className="cust-dash__project-grid-item cust-dash__project-grid-item--label">
                Total Serviço:
              </p>{" "}
              <p className="cust-dash__project-grid-item">
                {`R$ ${addThousandSeparator(memory.revenueData?.totalSvc)}`}
              </p>
              <p className="cust-dash__project-grid-item cust-dash__project-grid-item--label">
                Lucro por kWp:
              </p>{" "}
              <p className="cust-dash__project-grid-item">
                {`${addThousandSeparator(
                  memory.revenueData?.profit / memory.realSystemData?.power
                )} R$/kWp`}
              </p>
              <p className="cust-dash__project-grid-item cust-dash__project-grid-item--label">
                Lucro:
              </p>{" "}
              <p className="cust-dash__project-grid-item">
                {`R$ ${addThousandSeparator(memory.revenueData?.profit)}`}
              </p>
              <p className="cust-dash__project-grid-item cust-dash__project-grid-item--label">
                Valor Final por kWp:
              </p>{" "}
              <p className="cust-dash__project-grid-item">
                {`${addThousandSeparator(
                  memory.revenueData?.finalPrice / memory.realSystemData?.power
                )} R$/kWp`}
              </p>
              <p className="cust-dash__project-grid-item cust-dash__project-grid-item--label">
                Valor Final:
              </p>{" "}
              <p className="cust-dash__project-grid-item">
                {`R$ ${addThousandSeparator(memory.revenueData?.finalPrice)}`}
              </p>
            </div>
          </Fragment>
        </div>
      )}
    </Fragment>
  );
};
